import React from "react";
import Rotas from "./rotas";
import { ToastContainer } from "react-toastify";
import { createRoot } from "react-dom/client";
import { CarrinhoProvider } from "./contexts/carrinho-context.jsx";

import "./style/global.css";

createRoot(document.getElementById("root")).render(
    <CarrinhoProvider>
        <ToastContainer autoClose={5000} />
        <Rotas />
    </CarrinhoProvider>
);
