import React from 'react';
import { useContext } from 'react';
import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';
import Modal from 'react-modal/lib/components/Modal';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../../../api";
import './style.css';

import { AiFillCloseCircle } from "react-icons/ai";
import { useParams } from 'react-router-dom';


function ModalConfirmacao(props) {

    const params = useParams();

    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);

    const DeletarEndereco = async (codigoEndereco) => {

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));
        const session = localStorage.getItem(`@${params.identificacao}-SESSION`);

        const response = await api.delete(
            `usuario/endereco/deletar?codigoUsuario=${jsonUsuario.codigo}&codigoEndereco=${props.codigo}&identificacao=${params.identificacao}&session=${session}`
        );

        if (response?.response?.status) {
            toast.error("Erro ao deletar endereço, tente novamente.", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            return;
        }

        if (response?.data) {
            toast.success("Endereço deletado com sucesso.", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            props.ListarEnderecos(jsonUsuario.codigo);
            props.onCloseModal();
        }

    }

    return <Modal isOpen={props.isOpen}
        onRequestClose={props.onCloseModal}
        overlayClassName="modal-overlay"
        className="modal-background" >

        <div className="d-flex flex-column">
            <button onClick={props.onCloseModal} type="button" className="w-100 d-flex justify-content-end react-modal-close">
                <AiFillCloseCircle size={32} color='#555' />
            </button>

            <div className="container p-4">
                <div className="confirmacao-titulo d-flex justify-content-center">
                    Confirmação
                </div>
                <div className="text-center mt-4">
                    Confirma deleção do endereço: <span className="endereco-texto">{props.rua}, {props.numero}</span> ?
                </div>
                <div className="d-flex justify-content-center mt-4 gap-3">
                    <button className="btn-delecao-cancelar" style={{ backgroundColor: corBackground, color: corTexto }} onClick={props.onCloseModal}>Cancelar</button>
                    <button className="btn-delecao-confirmar" style={{ backgroundColor: corBackground, color: corTexto }} onClick={DeletarEndereco}>Confirmar</button>
                </div>
            </div>

        </div>

    </Modal>

}

export default ModalConfirmacao;