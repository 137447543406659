import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {toast} from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import "react-toastify/dist/ReactToastify.css";

import { LuVegan } from "react-icons/lu";
import { TbPlant } from "react-icons/tb";
import { TbCandyOff } from "react-icons/tb";
import { LuMilkOff } from "react-icons/lu";
import { GiFruitTree } from "react-icons/gi";
import { PiThermometerCold } from "react-icons/pi";

import { url_produto } from "../../../api.js";

import Modal from 'react-modal/lib/components/Modal';

import "./style.css";

import { AiFillCloseCircle } from "react-icons/ai";
import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';

Modal.setAppElement('#root');

function ModalProdutoOpcionais(props){

    const {addItemCarrinho} = useContext(CarrinhoContext);

    const [qtdeProduto, setQtdeProduto] = useState(1);
    const [totalProduto, setTotalProduto] = useState(0);
    const [somaTotalProduto, setSomaTotalProduto] = useState(0);

    const [obs, setObs] = useState('');

    const {corTexto} = useContext(CarrinhoContext);
    const {corBackground, corBackgroundHex} = useContext(CarrinhoContext);

    const [opcionais, setOpcionais] = useState([]);

    useEffect( ()=> {
        
        if( props.isOpen === false ){

            setQtdeProduto(1);
            setTotalProduto(0);
            setSomaTotalProduto(0);
            setObs('');
            setOpcionais([]);
        }

        if( props.isOpen === true){

            let jsonOpcionais = props.produto.opcionais;

            jsonOpcionais.forEach( opcional => {
                opcional.itens.forEach( itemOpcional => {
                    itemOpcional.quantidade = 0;
                });
            });

            setOpcionais(jsonOpcionais);
            setTotalProduto(props.produto.valorVendaPromocional > 0 ? props.produto.valorVendaPromocional : props.produto.valorVenda);
            setSomaTotalProduto(props.produto.valorVendaPromocional > 0 ? props.produto.valorVendaPromocional : props.produto.valorVenda);
        }
        
    }, [props.isOpen]);

    const updateJsonOpcionais = (newData) => {
        setOpcionais(newData);
    };

    function newAddAdditional(opcional, opcionalItem) {
        // Clonar o JSON de opcionais para evitar mutações diretas
        let jsonOpcionais = [...opcionais];

        // Encontrar o objeto com o código fornecido
        const opcionalIndex = jsonOpcionais.findIndex(
            opc => opc.codigo === opcional
        );
        
        // Se o objeto for encontrado
        if (opcionalIndex !== -1) {
            // Encontrar o item opcional dentro do objeto
            const itemIndex = jsonOpcionais[opcionalIndex].itens.findIndex(item => item.codigo === opcionalItem);
            
            // Se o item opcional for encontrado
            if (itemIndex !== -1) {
                const qtdeMax = jsonOpcionais[opcionalIndex].qtdeMax;

                //Somar a quantidade dos itens
                var quantidadeItens = jsonOpcionais[opcionalIndex].itens.reduce(function(acumulador, item) {
                    return acumulador + item.quantidade;
                }, 0);

                if( quantidadeItens < qtdeMax ){

                    jsonOpcionais[opcionalIndex].itens[itemIndex].quantidade += 1;
                
                    setTotalProduto(prevState => parseFloat(prevState) + parseFloat(jsonOpcionais[opcionalIndex].itens[itemIndex].valor));
                }
            }
    
            // Atualizar o JSON de opcionais
            updateJsonOpcionais(jsonOpcionais);
        }
    }

    function newRemoveAdditional(opcional, opcionalItem) {
        // Clonar o JSON de opcionais para evitar mutações diretas
        let jsonOpcionais = [...opcionais];
    
        // Encontrar o objeto com o código fornecido
        const opcionalIndex = jsonOpcionais.findIndex(
            opc => opc.codigo === opcional
        );
    
        // Se o objeto for encontrado
        if (opcionalIndex !== -1) {
            // Encontrar o item opcional dentro do objeto
            const itemIndex = jsonOpcionais[opcionalIndex].itens.findIndex(item => item.codigo === opcionalItem);
    
            // Se o item opcional for encontrado
            if (itemIndex !== -1) {
                // Incrementar a quantidade
                if( jsonOpcionais[opcionalIndex].itens[itemIndex].quantidade > 0 ){
                    jsonOpcionais[opcionalIndex].itens[itemIndex].quantidade -= 1;
                
                    setTotalProduto(prevState => parseFloat(prevState) - parseFloat(jsonOpcionais[opcionalIndex].itens[itemIndex].valor));
                }
                
            }
    
            // Atualizar o JSON de opcionais
            updateJsonOpcionais(jsonOpcionais);
        }
    }

    function verifyProducts(){

        let produtoIncompleto = false;

        for (const pedido of opcionais) {
            
            let somaQuantidades = pedido.itens.reduce((acc, opcional) => acc + opcional.quantidade, 0);
            
            if (!(somaQuantidades >= pedido.qtdeMin && somaQuantidades <= pedido.qtdeMax) && pedido.obrigatorio === 'S' ) {
                //chamar a função pra mostrar que está faltando
                verificaProdutoIncompleto(pedido.codigo);
                produtoIncompleto = true;
                break;
            }
        }        

        if( produtoIncompleto === false ){
            
            // const jsonOpcionais = [];
            const itensOpcionais = [];
          
            opcionais.forEach(item => {
                // Filtrar os itens opcionais com quantidade menor ou igual a zero
                const jsonOpcionais = item.itens.filter(opcional => opcional.quantidade > 0);
                
                // Adicionar os itens filtrados ao array de itens opcionais não utilizados
                itensOpcionais.push(...jsonOpcionais);
                
            });

            const produtoCarrinho = {
                uuid: uuidv4(),
                codigo: props.produto.codigo,
                nome: props.produto.nome,
                descricao: props.produto.descricao,
                valor: props.produto.valorVendaPromocional > 0 ? props.produto.valorVendaPromocional : props.produto.valorVenda,
                total: totalProduto,
                quantidade: qtdeProduto,
                foto: props.produto.foto,
                obs: obs,
                opcionais: itensOpcionais
            }

            addItemCarrinho(produtoCarrinho);
            toast.success("Produto adicionado ao carrinho.", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            props.onRequestClose();

        }
    }

    function verificaProdutoIncompleto( codigo ){

        var divOpcional = document.getElementById(codigo);
    
        divOpcional.scrollIntoView({ behavior: 'smooth', block: 'start' });
        divOpcional.classList.add("opcional-quantidade-erro");

        var intervaloAnimacao = setInterval(function() {
      
            divOpcional.classList.remove("opcional-quantidade-erro");
            clearInterval(intervaloAnimacao);
        }, 1000);
        
    
    }

    useEffect( () => {
        setSomaTotalProduto(parseFloat(totalProduto) * qtdeProduto);
    }, [totalProduto]);

    function addQtdeProduto(){
        setQtdeProduto(prevState => prevState + 1);
        setSomaTotalProduto(prevState => parseFloat(prevState) + parseFloat(totalProduto));
    }

    function removeQtdeProduto(){

        if( qtdeProduto > 1 ){
            setQtdeProduto(prevState => prevState - 1);
            setSomaTotalProduto(prevState => parseFloat(prevState) - parseFloat(totalProduto));
        }
        
    }
        
    return  <Modal  isOpen={props.isOpen} 
                    onRequestClose={props.onRequestClose} 
                    overlayClassName={"modal-overlay"} 
                    className={"modal-background"} >
        
                <div className="d-flex flex-column">
                    
                    <div className="area-fechar-modal d-flex justify-content-end fixed">
                        <button onClick={(e) => props.onRequestClose()} type="button" className="react-modal-close">
                            <AiFillCloseCircle size={32} color='#555'/>
                        </button>
                    </div>

                    <div className="modal-principal d-flex flex-column">
                        
                        <div className="modal-opcional-informacoes">
                            <div className="d-flex justify-content-center">
                                <span className="modal-opcional-produto-nome">{props.produto.nome}</span>
                            </div>
                            
                            <div className="modal-opcional-produto-imagem">
                                <img className="modal-opcional-produto-imagem-detalhes" src={url_produto + props.produto.foto} alt="Imagem produto" />
                            </div>

                            <div className="modal-opcional-produto-descricao">
                                <span>{props.produto.descricao}</span>
                            </div>
                        </div>

                        <div className='p-2'>
                            {
                                props.produto.vegetariano === 'S' ?
                                <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)`, color: corBackground }} title="Produto vegetariano">
                                    <LuVegan className="me-1" size={10} color={`rgb(${corBackgroundHex})`} />
                                    Produto Vegetariano
                                </span>
                                :
                                ''
                            }

                            {
                                props.produto.vegano === 'S' ?
                                <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                    <TbPlant className="me-1" size={10} color={`rgb(${corBackgroundHex})`} />
                                    Produto Vegano
                                </span>
                                :
                                ''
                            }

                            {
                                props.produto.acucar === 'S' ?
                                <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                    <TbCandyOff className="me-1" size={10} color={`rgb(${corBackgroundHex})`} />
                                    Produto zero açucar
                                </span>
                                :
                                ''
                            }

                            {
                                props.produto.lactose === 'S' ?
                                <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                    <LuMilkOff className="me-1" size={10} color={`rgb(${corBackgroundHex})`} />
                                    Produto zero lactose
                                </span>
                                :
                                ''
                            }

                            {
                                props.produto.organico === 'S' ?
                                <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                    <GiFruitTree className="me-1" size={10} color={`rgb(${corBackgroundHex})`} />
                                    Produto natural
                                </span>
                                :
                                ''
                            }

                            {
                                props.produto.gelado === 'S' ?
                                <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                <PiThermometerCold className="me-1" size={10} color={`rgb(${corBackgroundHex})`} />
                                Produto gelado  
                            </span>
                            :
                            ''
                            }
                        </div>

                        <div className="modal-opcional-produto-opcionais">   
                            {
                                opcionais.length > 0 ? opcionais.map( (opcional ) => {
                                    console.log()
                                    return  <div className="card mb-4" key={opcional.codigo}>
                                                    <div className="card-header d-flex align-items-center justify-content-between" style={{color: '#555', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '5px', paddingRight: '5px'}}>
                                                        <div className="d-flex flex-column" id={opcional.codigo} >
                                                            <span className="modal-opcional-opcionais-nome">{opcional.nome} {opcional.obrigatorio === 'S' ? <span className='badge-obrigatorio'>Obrigatorio</span> : ''    }</span>          
                                                            
                                                            {opcional.qtdeMin > 1 && opcional.qtdeMax === 0 ? <span className="modal-opcional-opcionais-quantidade">Escolha pelo menos {opcional.qtdeMax} opções</span> : 
                                                            opcional.qtdeMin === 1 && opcional.qtdeMax === 0 ? <span className="modal-opcional-opcionais-quantidade">Escolha pelo menos {opcional.qtdeMin} opção</span> : 
                                                            opcional.qtdeMin === 1 && opcional.qtdeMax > 1 ? <span className="modal-opcional-opcionais-quantidade">Escolha pelo menos {opcional.qtdeMin} e no máximo {opcional.qtdeMax} opções</span> :
                                                            opcional.qtdeMax > 1 && opcional.qtdeMin === 0 ? <span className="modal-opcional-opcionais-quantidade">Escolha até {opcional.qtdeMax} opções</span> :
                                                            opcional.qtdeMax === 1 && opcional.qtdeMin === 0 ? <span className="modal-opcional-opcionais-quantidade">Escolha até {opcional.qtdeMax} opção</span> :
                                                            opcional.qtdeMin > 1 && opcional.qtdeMax > 1 ? <span className="modal-opcional-opcionais-quantidade">Escolha pelo menos {opcional.qtdeMin} e no máximo {opcional.qtdeMax} opções</span> :
                                                            opcional.qtdeMin === 1 && opcional.qtdeMax === 1 ? <span className="modal-opcional-opcionais-quantidade">Escolha pelo menos {opcional.qtdeMin} e no máximo {opcional.qtdeMax} opção </span> 
                                                            :
                                                            null
                                                            }

                                                        </div>
                                                    </div>
                                                
                                                    <ul className="list-group list-group-flush" >
                                                    {
                                                    opcional.itens.map( (itensOpcional) => {
                                                    return  <li className="list-group-item" style={{color: '#555'}} key={itensOpcional.codigo}>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-flex flex-column justify-content-center">
                                                                        <span className="opcional-opcionais-nome">{itensOpcional.nome}</span>
                                                                        
                                                                        {
                                                                            itensOpcional.valor > 0 
                                                                            ?   
                                                                            <span className="opcional-opcoinais-valor">+ {new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(itensOpcional.valor)}</span> 
                                                                            :
                                                                            <span className="opcional-opcoinais-valor">GRÁTIS</span>
                                                                        }
                                                                        
                                                                    </div>
                                                                    {
                                                                    props.aberto === 'aberto' ? <div className="d-flex flex-row justify-content-between align-items-center opcional-quantidades gap-2">
                                                                        
                                                                        <button className="btn-qtde-opcional" style={{backgroundColor: corBackground, color: corTexto}} onClick={ (e) => {newRemoveAdditional( opcional.codigo, itensOpcional.codigo )} }>-</button>
                                                                        
                                                                        <span className="opcionais-opcional-qtde">{itensOpcional.quantidade}</span>
                                                                        
                                                                        <button className="btn-qtde-opcional" style={{backgroundColor: corBackground, color: corTexto}} onClick={ (e) => {newAddAdditional( opcional.codigo, itensOpcional.codigo )} } >+</button>
                                                                        
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex flex-row justify-content-between align-items-center opcional-quantidades gap-2">
                                                                        
                                                                        <button className="btn-qtde-opcional inativo" disabled={true} style={{backgroundColor: corBackground, color: corTexto}} onClick={ (e) => {newRemoveAdditional( opcional.codigo, itensOpcional.codigo )} }>-</button>
                                                                        
                                                                        <span className="opcionais-opcional-qtde inativo">{itensOpcional.quantidade}</span>
                                                                        
                                                                        <button className="btn-qtde-opcional inativo" disabled={true} style={{backgroundColor: corBackground, color: corTexto}} onClick={ (e) => {newAddAdditional( opcional.codigo, itensOpcional.codigo )} } >+</button>
                                                                        
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                    </ul>
                                                </div>
                                })
                                :
                                null
                            }
                        </div>

                        <div className="modal-produto-obs">
                            <label htmlFor="validationTextarea" className="form-label">Alguma observação?</label>
                            <textarea className="observacao-input form-control" maxLength={100} placeholder="Ex: sem salada sem queijo etc..." value={obs} onChange={ (e) => setObs(e.target.value)}></textarea>

                            <div className="observacao-caracters d-flex justify-content-end mt-2">
                                <span>{obs.length} / 100</span>
                            </div>

                        </div>

                    </div>

                    <div className="modal-produto-quantidade d-flex justify-content-end align-items-center gap-3">
                        {
                        props.aberto === 'aberto' ? <div className="d-flex align-items-center gap-2">
                            <button className="btn-quantidade-produto" onClick={ (e) => {removeQtdeProduto()} } style={{backgroundColor: corBackground, color: corTexto}}>-</button>
                            <span>{qtdeProduto}</span>
                            <button className="btn-quantidade-produto" onClick={ (e) => {addQtdeProduto()} } style={{backgroundColor: corBackground, color: corTexto}}>+</button>
                        </div>
                        :
                        <div className="d-flex align-items-center gap-2">
                            <button className="btn-quantidade-produto inativo" disabled={true} onClick={ (e) => {removeQtdeProduto()} } style={{backgroundColor: corBackground, color: corTexto}}>-</button>
                            <span className="inativo">{qtdeProduto}</span>
                            <button className="btn-quantidade-produto inativo" disabled={true} onClick={ (e) => {addQtdeProduto()} } style={{backgroundColor: corBackground, color: corTexto}}>+</button>
                        </div>
                        }
                        
                        
                        {
                        props.aberto === 'aberto' ? <button className="btn-adicionar-carrinho" style={{backgroundColor: corBackground, color: corTexto}} onClick={ (e) => {verifyProducts()} }>Adicionar {new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(somaTotalProduto)}</button>
                        :
                        <button className="btn-adicionar-carrinho inativo" disabled={true} style={{backgroundColor: corBackground, color: corTexto}} onClick={ (e) => {verifyProducts()} }>Fechado</button>
                        }
                        
                    
                    </div>
                    
                </div>
                
            </Modal>

}

export default ModalProdutoOpcionais;