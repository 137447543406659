import { useContext, useEffect, useState } from "react";

import { CiUser } from "react-icons/ci";
import { IoCartOutline } from "react-icons/io5";

import Carrinho from "../carrinho/index.jsx";
import ModalLogin from "../login/index.jsx";
import { CarrinhoContext } from "../../contexts/carrinho-context.jsx";

import "./navbar.css";

import { IoMdCart } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { Navigate, useNavigate, useParams} from "react-router-dom";
import { api } from "../../api.js";

function NavBar(props){

    const navigate = useNavigate();
    const params = useParams();

    const {totalCarrinho} = useContext(CarrinhoContext);
    const {qtdeItensCarrinho} = useContext(CarrinhoContext);
    const {carrinho} = useContext(CarrinhoContext);

    const {setIsLogged, isLogged} = useContext(CarrinhoContext);
    const {setLoginNome, setLoginNumero} = useContext(CarrinhoContext);

    const {corBackground} = useContext(CarrinhoContext);
    const {corTexto} = useContext(CarrinhoContext);

    const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);

    const Desconectar = () => {
        const nome_estabelecimento = JSON.parse(localStorage.getItem(`@${params.identificacao}-DADOS`));
        const user = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));

        api.post(`cliente/deslogar?identificacao=${nome_estabelecimento.estabelecimento}&codigo=${user.codigo}`)
        .then((response) =>{
            localStorage.removeItem(`@${nome_estabelecimento.identificacao}-SESSION`);
            localStorage.removeItem(`@${nome_estabelecimento.estabelecimento}-USERDADOS`);
            localStorage.removeItem(`@${nome_estabelecimento.estabelecimento}-SESSIONCART`);
            window.location.reload();
        })
        .catch((err) =>{
            console.log(err)
        })
    }

    function openModalLogin(){
        setIsOpenModalLogin(!isOpenModalLogin);
    }

    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        function handleResize() {
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return <div>

        <ModalLogin isOpen={isOpenModalLogin}
                    onRequestClose={openModalLogin} />

        <div className="navbar-menu" style={{backgroundColor: corBackground}}>
        <div className="container">
            <div className="container-navbar">
                <div className="navbar-opcoes d-flex justify-content-end gap-2 pt-3">
                    
                    {
                        screenSize.width <= 768 ?
                        ''
                        :
                        carrinho.length > 0 ? 
                        <button className="btn-carrinho d-flex align-items-center gap-2" style={{ color: corBackground, border: `1px solid ${corBackground}`, backgroundColor: corTexto}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" >
                            <div>
                                <span> <IoCartOutline  fontSize={24}/> </span>
                            </div>
                            <div className="d-flex flex-column justify-content-end">
                                <span>{qtdeItensCarrinho} {qtdeItensCarrinho > 1 ? ' Itens' : 'Item'}</span>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCarrinho)}</span>
                            </div>
                        </button>
                        :
                        null
                    }
                    
                    {
                        screenSize.width <= 768 ?
                        ''
                        :
                        !isLogged ? 
                        <button className="btn-conta" onClick={openModalLogin} style={{color: corBackground, border: `1px solid ${corBackground}`, backgroundColor: corTexto}}>
                            <CiUser color={corBackground} size={16} className="me-2" />
                            Entrar/Cadastrar
                        </button> 
                        :
                        <div className="dropdown">
                            <button className="btn-conta" style={{backgroundColor: corTexto, color: corBackground}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FaUser color={corBackground} size={16} className="me-2" />
                                Minha conta
                            </button>
                            <ul className="dropdown-menu">
                                <li className="navbar-item">    
                                    <button className="btn-navbar-opcao" style={{backgroundColor: 'transparent', color: corBackground}} onClick={ () => {navigate(`/${params.identificacao}/meuspedidos`)}}>Meus pedidos</button>
                                </li>
                                <li className="navbar-item">    
                                    <button className="btn-navbar-opcao" style={{backgroundColor: 'transparent', color: corBackground}} onClick={ () => {navigate(`/${params.identificacao}/perfil`)}}>Perfil</button>
                                </li>
                                <div className="borda"></div>
                                <li className="navbar-item">
                                    <button className="btn-navbar-opcao" onClick={Desconectar} style={{backgroundColor: 'transparent', color: corBackground}}>Desconectar</button>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>       
        </div> 

        
    </div>

    <div className="carrinho">
        <div className="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" >
            <Carrinho openModalFinalizarPedido={props.openModal}
                        aberto={props.aberto}
                        identificacao={props.identificacao}
                        totalCarrinho={totalCarrinho}
                        valorPedidoMinimo={props.valorPedidoMinimo} />
        </div>
    </div>
    </div>



}

export default NavBar;