
import { url_produto } from '../../../api';
import './style.css';

function CardItem (props) {

    return (
        <div className="pedido-card-item">
            
            <div className="pedido-card-item-nome">
                <img className="foto-produto-carrinho me-2" src={url_produto + props.foto} />    
                {props.quantidade}x - {props.nome}
            </div>

            <div className="borda"></div>

            {
                props.opcionais.map( (op) =>{
                    return (
                    <div className="pedido-card-item-opcionais d-flex justify-content-between">
                        <span>{op.nome}</span>
                        {
                        op.valor > 0 ? <span className="pedido-card-item-opcionais-valor">+ {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(op.valor)}</span>
                        :
                        <span className="pedido-card-item-opcionais-valor">GRÁTIS</span>
                        }
                    </div>
                    )
                })
            }
            {
                props.obs.length > 0 ? (
                    <div className="pedido-card-item-observacao">
                        <span>Obs: {props.obs}</span>
                    </div>
                )
                :
                null
            }
            {
            props.opcionais.length > 0 ? <div className="borda"></div> : null
            }

            <div className="pedido-card-item-total d-flex justify-content-end">
                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.total)}</span>
            </div>

        </div>
    )

}

export default CardItem;