import Modal from 'react-modal/lib/components/Modal';
import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';

import './style.css';

import { AiFillCloseCircle } from "react-icons/ai";
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function ModalEditarEndereco(props) {

    const params = useParams();

    const [codigo, setCodigo] = useState(0);
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');
    const [cep, setCep] = useState('');

    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);

    const [isSavingEndereco, setIsSavingEndereco] = useState(false);

    useEffect(() => {

        if (props.isOpen === true) {
            setRua(props.rua);
            setNumero(props.numero);
            setBairro(props.bairro);
            setComplemento(props.complemento);
            setCidade(props.cidade);
            setUf(props.uf);
            setCep(props.cep);
        }

    }, [props.isOpen]);

    const SalvarEndereco = async () => {

        setIsSavingEndereco(true);

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));
        const session = localStorage.getItem(`@${params.identificacao}-SESSION`);

        const jsonEndereco = {
            rua,
            numero,
            bairro,
            complemento,
            cidade,
            uf,
            cep
        }

        const response = await api.patch(
            `usuario/endereco/editar?codigoUsuario=${jsonUsuario.codigo}&codigoEndereco=${props.codigoEndereco}&identificacao=${params.identificacao}&session=${session}`,
            jsonEndereco
        );

        if (response?.response?.status) {
            toast.error("Erro ao editar endereço, tente novamente.", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            setIsSavingEndereco(false);

            return;
        }

        if (response?.status === 200) {
            toast.success("Endereço editado com sucesso.", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            setIsSavingEndereco(false);
            props.ListarEnderecos(jsonUsuario.codigo);
            props.onCloseModal();

        }

    }

    return <Modal isOpen={props.isOpen}
        onRequestClose={props.onCloseModal}
        overlayClassName="modal-overlay"
        className="modal-background" >

        <div className="d-flex flex-column">
            <button onClick={props.onCloseModal} type="button" className="w-100 d-flex justify-content-end react-modal-close">
                <AiFillCloseCircle size={32} color='#555' />
            </button>

            <div className="container pb-4">
                <div className="d-flex justify-content-center">
                    <span className="endereco-titulo">Editar endereço</span>
                </div>

                <div className="novoendereco-cep">
                    <div className="row">
                        <div className="col-md-9 col-sm-12 d-flex flex-column mb-3">
                            <label>Rua</label>
                            <input className="input-w100" type="text" value={rua} onChange={(e) => setRua(e.target.value)} />
                        </div>
                        <div className="col-md-3 col-sm-12 d-flex flex-column mb-3">
                            <label>Número</label>
                            <input className="input-w100" type="text" value={numero} onChange={(e) => setNumero(e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 d-flex flex-column mb-3">
                            <label>Complemento</label>
                            <input className="input-w100" type="text" value={complemento.length > 0 ? complemento : ''} onChange={(e) => setComplemento(e.target.value)} />
                        </div>
                        <div className="col-md-6 col-sm-12 d-flex flex-column mb-3">
                            <label>Bairro</label>
                            <input className="input-w100" type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 d-flex flex-column mb-3">
                            <label>Cidade</label>
                            <input className="input-w100" value={cidade} type="text" disabled={true} onChange={(e) => setCidade(e.target.value)} />
                        </div>
                        <div className="col-md-2 col-sm-12 d-flex flex-column mb-3">
                            <label>Uf</label>
                            <input className="input-w100" value={uf} type="text" maxLength={2} disabled={true} onChange={(e) => setUf(e.target.value)} />
                        </div>
                        <div className="col-md-4 col-sm-12 d-flex flex-column mb-3">
                            <label>Cep</label>
                            <input className="input-w100" value={cep} type="text" maxLength={9} disabled={true} onChange={(e) => setCep(e.target.value)} />
                        </div>
                    </div>

                    <button className="btn-salvar-endereco"
                        style={{ backgroundColor: corBackground, color: corTexto }}
                        onClick={SalvarEndereco}
                        disabled={
                            rua === '' || numero === '' || bairro === '' || cidade === '' ||
                                uf === '' || cep === '' ? true : false}>
                        {
                            isSavingEndereco === true ? <div className="d-flex justify-content-center align-items-center gap-2">
                                <div className="spinner-border spinner-border-sm" role="status"></div>
                                <span>Salvando...</span>
                            </div>
                                :
                                rua === '' || numero === '' || bairro === '' || cidade === '' ||
                                    uf === '' || cep === '' ? 'Preencha os dados' : 'Salvar'
                        }
                    </button>

                </div>
            </div>

        </div>

    </Modal>
}

export default ModalEditarEndereco;