import { useContext } from "react";
import { ReactComponent as Delivery } from "../../../assets/images/delivery.svg";
import { ReactComponent as Retirada } from "../../../assets/images/retirada.svg";
import {ReactComponent as Encomenda} from "../../../assets/images/encomenda.svg";

import { CarrinhoContext } from "../../../contexts/carrinho-context";

import './style.css';

function TipoEntrega(props) {

    const { setCorBackground, corBackground } = useContext(CarrinhoContext);
    const { setCorTexto, corTexto } = useContext(CarrinhoContext);

    return  (
        <div onClick={(e) => props.buscaEndereco()} className="col-lg-6 col-md-6 col-sm-12">
            <div 
            className="tipo-entrega d-flex align-items-center gap-2" 
            style={props.TipoEntregaSelecionado === props.TipoEntrega ? {border: `1px solid ${corBackground}`} : {border: `1px solid #ccc`}}
            onClick={ (e) => {props.alterarTipoEntrega(props.TipoEntrega)}}
            >
                {
                    props.TipoEntrega === 1 ? (
                        <Delivery 
                            width={32} 
                            height={32} 
                            fill={props.TipoEntregaSelecionado === props.TipoEntrega ? `${corBackground}` : 'black'} 
                        />    
                    )
                    :
                    null
                }

                {
                    props.TipoEntrega === 2 ? (
                        <Retirada 
                            width={32} 
                            height={32} 
                            fill={props.TipoEntregaSelecionado === props.TipoEntrega ? `${corBackground}` : 'black'} 
                        />    
                    )
                    :
                    null
                }

                {
                    props.TipoEntrega === 3 ? (
                        <Encomenda 
                            width={28} 
                            height={28} 
                            fill={props.TipoEntregaSelecionado === props.TipoEntrega ? `${corBackground}` : 'black'} 
                        />    
                    )
                    :
                    null
                }


                <span className="tipo-entrega-nome">{props.Nome}</span>
            </div>
        </div>
    )
}

export default TipoEntrega;