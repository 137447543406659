import React, { useContext, useEffect, useState } from 'react';
import InputCodigo from '../../components/inputcodigo';
import { CarrinhoContext } from '../../contexts/carrinho-context';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { api } from '../../api';

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AlterarSenha(){

    const { identificacao } = useParams();
    const navigate = useNavigate();

    const { corBackground } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);

    const [isLoading, setIsLoading] = useState(false);

    const [senha, setSenha] = useState("");
    const [confirmaSenha, setConfirmaSenha] = useState("");

    const [token, setToken] = useState("");

    const[isCodigoValidado, setIsCodigoValidado] = useState(false); 

    function handleComplete(code){
        
        let session = localStorage.getItem(`@${identificacao}-SESSION`);
        let jsonUsuarioLogado = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`));

        setIsLoading(true);

        if(code === ''){
            alert('Digite um código válido');
            setIsLoading(false);
            return;
        }

        api.get(`usuario/recuperar-senha/verifica-codigo?identificacao=${identificacao}&session=${session}&codigo_recuperacao=${code}&codigocliente=${jsonUsuarioLogado.codigo}`)
        .then(response => {
            if(response.status === 200){
                setIsCodigoValidado(response.data.codigo_validado);
                setToken(response.data.token);
                toast.success("Código validado com sucesso!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            
            if(response.status === 405){
                toast.error("Código inválido!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            setIsLoading(false);
        })
        .catch(err => {
            toast.error("Erro ao validar o código, tente novamente mais tarde!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        });
    
    };

    function handleAlteraSenha(){

        setIsLoading(true);

        let jsonUsuarioLogado = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`));

        if(senha === ""){
            alert('Informe a senha');
            setIsLoading(false);
            return;
        }

        if(confirmaSenha === ""){
            alert('Informe a confirmação da senha');
            setIsLoading(false);
            return;
        }

        if(senha !== confirmaSenha){
            alert('As senhas não coincidem');
            setIsLoading(false);

            return;
        }

        api.post(`usuario/recuperar-senha/editar-senha?identificacao=${identificacao}&codigocliente=${jsonUsuarioLogado.codigo}&token=${token}&senha=${senha}`)
        .then(response =>{
            if(response.status === 200){
                toast.success("Senha alterada com sucesso!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
    
                setIsLoading(false);
                navigate(`/${identificacao}`);
            }
        })
        .catch(err => {
            if(err.response.status === 405){

                setIsLoading(false);

                toast.success(err.response.data, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                return;
            }

            if(err.response.status === 500){
                setIsLoading(true);
                
                toast.danger("Erro ao alterar senha, tente novamente mais tarde!", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                return;
            }
        })
    }

    useEffect(() =>{
        let jsonUsuarioLogado = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`));
        
        if(jsonUsuarioLogado === undefined || jsonUsuarioLogado === '' || jsonUsuarioLogado === null){
            navigate(`/${identificacao}`);
        }
    },[])

    return (
        <div className='container'>
            { 
                isCodigoValidado ?
                <div className='checkout-login rounded' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
                    <h2>Altere sua senha.</h2>
                    <p>Digite a baixo nos campos a sua nova senha</p>
                    
                    <div className='col-12'>
                        <div className="mb-3">
                            <label htmlFor="senha" className="form-label">Senha</label>
                            <input onChange={(e) => setSenha(e.target.value)} value={senha} type="password" className="form-control" id="senha" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Confirma senha</label>
                            <input onChange={(e) => setConfirmaSenha(e.target.value)} value={confirmaSenha} type="password" className="form-control" id="confirmasenha" />
                        </div>
                    </div>

                    <button onClick={(e) => handleAlteraSenha()} className="btn-acessar" style={{backgroundColor: corBackground, color: corTexto, marginTop: '30px'}} disabled={isLoading}>
                        {
                            isLoading === true ? 
                                (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                    </div>
                                ) 
                                : 
                                (
                                    <span>Salvar senha</span>
                                )
                        }
                    </button>
                </div>
                :
                <div className='checkout-login rounded' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
                    <h2>Recuperação de Senha</h2>
                    <p>Digite o código enviado para o seu e-mail ou SMS:</p>
                    
                    <InputCodigo length={6} onComplete={handleComplete} />

                    <button className="btn-acessar" style={{backgroundColor: corBackground, color: corTexto, marginTop: '30px'}} disabled={isLoading}>
                        {
                            isLoading === true ? 
                                (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                    </div>
                                ) 
                            : 
                                (
                                    <span>Enviar código</span>
                                )
                        }
                    </button>
                </div>
            }
        </div>
    );
};

export default AlterarSenha;
