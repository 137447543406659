import { useContext, useState, useEffect } from 'react';
import ModalConfirmacao from '../modalConfirmacao';
import ModalEditarEndereco from '../modalEditarModal';

import { CarrinhoContext } from "../../../contexts/carrinho-context.jsx";
import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../../../api";

import './style.css';

import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";

function Endereco(props) {


    const [isOpenModalEditarEndereco, setIsOpenModalEditarEndereco] = useState(false);
    const [isOpenModalConfirmaDelecao, setIsOpenModalConfirmaDelecao] = useState(false);

    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);

    function AbrirModalEditarEndereco() {
        setIsOpenModalEditarEndereco(!isOpenModalEditarEndereco);
    }

    function ModalConfirmaDelecao(){
        setIsOpenModalConfirmaDelecao(!isOpenModalConfirmaDelecao);
    }


    return <div>
        <div className="row mb-2">
            <ModalConfirmacao   isOpen={isOpenModalConfirmaDelecao}
                                onCloseModal={ModalConfirmaDelecao}
                                codigo={props.codigo}
                                rua={props.rua}
                                numero={props.numero}
                                ListarEnderecos={props.ListarEnderecos} />  

            <ModalEditarEndereco isOpen={isOpenModalEditarEndereco}
                onCloseModal={AbrirModalEditarEndereco}
                codigoEndereco={props.codigo}
                rua={props.rua}
                numero={props.numero}
                bairro={props.bairro}
                complemento={props.complemento}
                cidade={props.cidade}
                uf={props.uf}
                cep={props.cep}
                ListarEnderecos={props.ListarEnderecos} />

            <div className="col-12">
                <div className="card-endereco"
                style={props.EnderecoSelecionado === props.codigo ? {border: `1px solid ${corBackground}`, color: corBackground} : {border: `1px solid #ccc`}}
                onClick={(e) => { props.HandleSelecionarEndereco(props.codigo) }}>
                    <div className="w-100 d-flex justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="card-endereco-texto">{props.rua}, {props.numero} {props.complemento != '' ? ` - ${props.complemento}` : ''} - {props.bairro} </span>
                            <span className="card-endereco-texto">{props.cidade} - {props.uf}</span>
                        </div>
                        <div className="d-flex align-items-center">

                            <div className="dropdown">
                                <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                </button>
                                <ul className="dropdown-menu">
                                    <div className="d-flex flex-column p-2 gap-2">
                                        <button className="btn-opcao" style={{ backgroundColor: corBackground, color: corTexto }} onClick={AbrirModalEditarEndereco}>Editar</button>
                                        <button className="btn-opcao" style={{ backgroundColor: corBackground, color: corTexto }} onClick={ModalConfirmaDelecao}>Deletar</button>
                                    </div>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
}

export default Endereco;