import { useContext, useEffect, useState } from "react";
import Carrinho from "../carrinho/index.jsx";
import ModalLogin from "../login/index.jsx";
import { CarrinhoContext } from "../../contexts/carrinho-context.jsx";
import { FaMagnifyingGlass } from "react-icons/fa6";
import "./navbar.css";
import { useNavigate, useParams } from "react-router-dom";

import { CiUser } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { api, url_imagem } from "../../api.js";

function NavBar2({ scrollToCategory, selectedCategory, setSelectedCategory, categorias, openModal, aberto, identificacao, valorPedidoMinimo, logo }) {
    const { totalCarrinho } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);

    const {setIsLogged, isLogged} = useContext(CarrinhoContext);
    const {setLoginNome, setLoginNumero} = useContext(CarrinhoContext);

    const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);

    const {qtdeItensCarrinho} = useContext(CarrinhoContext);
    const {carrinho} = useContext(CarrinhoContext);
    
    const navigate = useNavigate();
    const params = useParams();

    function openModalLogin() {
        setIsOpenModalLogin(!isOpenModalLogin);
    }

    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (e) => {
        scrollToCategory(e)
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const Desconectar = () => {
        const nome_estabelecimento = JSON.parse(localStorage.getItem(`@${params.identificacao}-DADOS`));
        const user = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));

        api.post(`cliente/deslogar?identificacao=${nome_estabelecimento.estabelecimento}&codigo=${user.codigo}`)
        .then((response) =>{
            localStorage.removeItem(`@${identificacao}-SESSION`);
            localStorage.removeItem(`@${identificacao}-USERDADOS`);
            localStorage.removeItem(`@${identificacao}-SESSIONCART`);
            window.location.reload();
        })
        .catch((err) =>{
            console.log(err)
        })
    }

    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        function handleResize() {
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Fecha o dropdown se clicar fora
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!e.target.closest('.dropdown')) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <ModalLogin isOpen={isOpenModalLogin} onRequestClose={openModalLogin} />
            <div className="navbar-menu2 fixed-top" style={{ backgroundColor: corBackground }}>
                <div className="p-2">
                    <div className="container-navbar">
                        <div className="navbar-opcoes d-flex justify-content-between align-items-center ps-lg-3">
                            <div className="d-flex">
                                <img className="logo-navbar" src={url_imagem + logo} />
                                
                                <div className="d-flex mt-2 ms-4">
                                    <div className="dropdown me-2" style={{width: '250px'}} onClick={(e) => e.stopPropagation()}>
                                        <div className="dropdown-button" onClick={handleToggle}>
                                            <span className="categoria">{selectedCategory}</span>
                                        </div>
                                        {isOpen && (
                                            <div className="dropdown-content no-select">
                                                <div id="top" onClick={handleSelect}>
                                                    Voltar ao topo
                                                </div>
                                                {categorias.map((category) => (
                                                    <div className="no-select" id={category.nome} key={category.id} onClick={(e) => handleSelect(e)}>
                                                        {category.nome}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <button data-bs-toggle="offcanvas" data-bs-target="#modal-pesquisa" className="custom-button">
                                        <FaMagnifyingGlass />
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex">
                                {
                                    screenSize.width <= 768 ?
                                    ''
                                    :
                                    carrinho.length > 0 ? 
                                    <button className="btn-carrinho d-flex align-items-center gap-2 ms-2 me-2" style={{ color: corBackground, border: `1px solid ${corBackground}`, backgroundColor: corTexto}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" >
                                        <div>
                                            <span> <IoCartOutline  fontSize={24}/> </span>
                                        </div>
                                        <div className="d-flex flex-column justify-content-end">
                                            <span>{qtdeItensCarrinho} {qtdeItensCarrinho > 1 ? ' Itens' : 'Item'}</span>
                                            <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCarrinho)}</span>
                                        </div>
                                    </button>
                                    :
                                    null
                                }
                        

                                {
                                    screenSize.width <= 768 ?
                                    ''
                                    :
                                    !isLogged ? 
                                    <button className="btn-conta" onClick={openModalLogin} style={{color: corBackground, border: `1px solid ${corBackground}`, backgroundColor: corTexto}}>
                                        <CiUser color={corBackground} size={16} className="me-2" />
                                        Entrar/Cadastrar
                                    </button> 
                                    :
                                    <div className="dropdown">
                                        <button className="btn-conta" style={{backgroundColor: corTexto, color: corBackground}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FaUser color={corBackground} size={16} className="me-2" />
                                            Minha conta
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li className="navbar-item">    
                                                <button className="btn-navbar-opcao" style={{backgroundColor: 'transparent', color: corBackground}} onClick={ () => {navigate(`/${params.identificacao}/meuspedidos`)}}>Meus pedidos</button>
                                            </li>
                                            <li className="navbar-item">    
                                                <button className="btn-navbar-opcao" style={{backgroundColor: 'transparent', color: corBackground}} onClick={ () => {navigate(`/${params.identificacao}/perfil`)}}>Perfil</button>
                                            </li>
                                            <div className="borda"></div>
                                            <li className="navbar-item">
                                                <button className="btn-navbar-opcao" onClick={Desconectar} style={{backgroundColor: 'transparent', color: corBackground}}>Desconectar</button>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="carrinho">
                <div className="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <Carrinho 
                        openModalFinalizarPedido={openModal}
                        aberto={aberto}
                        identificacao={identificacao}
                        totalCarrinho={totalCarrinho}
                        valorPedidoMinimo={valorPedidoMinimo} 
                    />
                </div>
            </div>
        </div>
    );
}

export default NavBar2;
