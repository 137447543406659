import { Dock } from "react-dock";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

import CarrinhoItem from "../carrinhoitem/index.jsx";

import { CarrinhoContext } from "../../contexts/carrinho-context.jsx";


import { v4 as uuidv4 } from 'uuid';

import { AiFillCloseCircle } from "react-icons/ai";

import "./style.css";

function Carrinho(props) {

    const navigate = useNavigate();
    const { identificacao } = useParams();

    const [show, setShow] = useState(false);

    const { carrinho } = useContext(CarrinhoContext);
    const { totalCarrinho } = useContext(CarrinhoContext);

    const { setCodigoCupom } = useContext(CarrinhoContext);
    const { setDescontoCarrinho } = useContext(CarrinhoContext);
    const { setMsgCupom } = useContext(CarrinhoContext);

    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);
    
    useEffect(() => {

        if (carrinho.length === 0) {
            return;
        }

    }, [show]);

    function openModalFinalizarPedido() {

        navigate(`/${identificacao}/checkout`);

    }

    return <div className="h-100">
        <div className="sacola-titulo d-flex align-items-center">
            <span className="w-100 text-center">Meu Carrinho</span> 
            <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" className="d-flex align-items-center btn-fechar">
                <AiFillCloseCircle size={32} color='#555'/>
            </button>   
        </div>

        <div className="sacola-lista-itens">
            {
                carrinho.length > 0 ? carrinho.map((item, i) => {
                    return <CarrinhoItem key={uuidv4()}
                        uuid={item.uuid}
                        codigo={item.codigo}
                        nome={item.nome}
                        quantidade={item.quantidade}
                        valor={item.valor}
                        total={item.total}
                        foto={item.foto}
                        obs={item.obs}
                        opcionais={item.opcionais} />
                })
                    :
                    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                        <span className="text-center">😴</span>
                        <span className="text-center">Seu carrinho está vazio...</span>
                    </div>
            }
        </div>
        
        {
            carrinho.length > 0 ?
            <div className="sacola-valores d-flex flex-column">

                <span className="sacola-valores-total">SubTotal pedido: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCarrinho)}</span>

                <div className="sacola-finalizar-pedido">
                    {
                        props.aberto === 'aberto' ? <button 
                                                    className="btn-finalizar-pedido" 
                                                    style={{ backgroundColor: corBackground, color: corTexto }} 
                                                    onClick={(e) => openModalFinalizarPedido()} 
                                                    disabled={carrinho.length === 0 || props.totalCarrinho <= props.valorPedidoMinimo ? true : false} >
                        {
                            props.totalCarrinho <= props.valorPedidoMinimo ? 'Valor mínimo não atingido.' : 'Finalizar pedido'
                        }
                        </button>
                        :
                        props.aberto === 'fechado' ? <button className="btn-finalizar-pedido inativo" style={{ backgroundColor: corBackground, color: corTexto }} onClick={(e) => openModalFinalizarPedido()} disabled={true}>
                            Restaurante fechado
                        </button>
                        :
                        null
                    }
                </div>
            </div>
            :
            ''
        }
    </div>
}

export default Carrinho;