import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";

import Home from "../src/pages/home/home.jsx";
import Cardapio from "./pages/cardapio/cardapio.jsx";
import VisualizarPedido from "./pages/pedido/index.jsx";
import Checkout from "./pages/checkout/index.jsx";
import MeusPedidos from "./pages/meuspedidos/index.jsx";
import NotFound from "./pages/404/index.jsx";
import Perfil from "./pages/perfil/index.jsx";
import RecuperarSenha from "./pages/recuperar-senha/index.jsx";
import AlterarSenha from "./pages/alterarsenha/index.jsx";

function Rotas() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/:identificacao" element={<Cardapio />} />
                    <Route exact path="/:identificacao/checkout" element={<Checkout />} />
                    <Route exact path="/:identificacao/pedido/:codigoPedido" element={<VisualizarPedido />} />
                    <Route exact path="/:identificacao/perfil" element={ <Perfil /> } />
                    <Route exact path="/:identificacao/meuspedidos" element={ <MeusPedidos /> } />
                    <Route exact path="/:identificacao/recuperarsenha" element={ <RecuperarSenha /> } />
                    <Route exact path="/:identificacao/alterarsenha" element={ <AlterarSenha /> } />
                    <Route exact path="/404" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default Rotas;
