import React, { useRef, useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import { useParams, useNavigate } from "react-router-dom";
import { FaMagnifyingGlass } from "react-icons/fa6";
import Cabecalho from "../../components/cabecalho/index.jsx";
import ProdutoCardapio from "../../components/produtos-cardapio/produto/index.jsx";
import NavBar from "../../components/navbar/index.jsx";
import ModalProdutoOpcionais from "../../components/produtos-cardapio/modalDetalhesProduto/index.jsx";
import LoaderTelaToda from "../../components/loader/LoaderTelaToda/index.jsx";
import { CarrinhoContext } from "../../contexts/carrinho-context.jsx";
import { api, url_imagem, url_site } from "../../api.js";
import NavBar2 from "../../components/navbar2/index.jsx";
import MenuMobile from "../../components/menu-mobile/index.jsx";
import SacolaMobile from "../../components/sacola-mobile/index.jsx";
import "./cardapio.css";
import PesquisaProduto from "../../components/pesquisa-cardapio/index.jsx";

import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

function Cardapio() {
    const params = useParams();
    const navigate = useNavigate();

    const {setIsVerifyed} = useContext(CarrinhoContext);
    const {isAutenticaded, setIsAutenticaded} = useContext(CarrinhoContext);

    const { corTexto, corBackground, carrinho, setEstabelecimentoNome, setCorBackground, setCorTexto, setValorTaxaEntrega, setTempoEntrega, setTempoRetirada, setEstabelecimentoWhatsapp, setIsLogged } = useContext(CarrinhoContext);
    const { estabelecimentoNome, valorTaxaEntrega, tempoEntrega, tempoRetirada, estabelecimentoWhatsapp } = useContext(CarrinhoContext);
    const { setCorBackgroundHex } = useContext(CarrinhoContext);

    const [isLoadingEstabelecimento, setIsLoadingEstabelecimento] = useState(true);
    const [estabelecimentoStatusMsg, setEstabelecimentoStatusMsg] = useState('');
    const [estabelecimentoStatus, setEstabelecimentoStatus] = useState('');
    const [estabelecimentoLogo, setEstabelecimentoLogo] = useState('');
    const [estabelecimentoBanner, setEstabelecimentoBanner] = useState('');
    const [estabelecimentoValorMinimo, setEstabelecimentoValorMinimo] = useState(0);
    const [estabelecimentoRua, setEstabelecimentoRua] = useState('');
    const [estabelecimentoNumero, setEstabelecimentoNumero] = useState('');
    const [estabelecimentoBairro, setEstabelecimentoBairro] = useState('');
    const [estabelecimentoComplemento, setEstabelecimentoComplemento] = useState('');
    const [estabelecimentoCidade, setEstabelecimentoCidade] = useState('');
    const [estabelecimentoUf, setEstabelecimentoUf] = useState('');
    const [estabelecimentoInstagram, setEstabelecimentoInstagram] = useState('');
    const [estabelecimentoFacebook, setEstabelecimentoFacebook] = useState('');
    const [estabelecimentoEntregas, setEstabelecimentoEntregas] = useState([]);
    const [estabelecimentoHorarios, setEstabelecimentoHorarios] = useState([]);
    const [estabelecimentoPagamentos, setEstabelecimentoPagamentos] = useState([]);
    const [estabelecimentoProdutos, setEstabelecimentoProdutos] = useState([]);
    const [isOpenModalProdutoOpcionais, setIsOpenModalProdutoOpcionais] = useState(false);
    const [produtoClicado, setProdutoClicado] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Voltar ao topo');

    function hexToRgb(hex) {
        // Remove o caractere # se presente
        hex = hex.replace(/^#/, '');
    
        // Pega os valores de vermelho, verde e azul
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
    
        return `${r}, ${g}, ${b}`;
    }

    const [aberto, setAberto] = useState(false);
    
    const currentYear = new Date().getFullYear();

    const ListarDadosEstabelecimento = async () => {
        const response = await api.get(`estabelecimento/listar?identificacao=${params.identificacao}`);
        if (response?.response?.data) {
            navigate("/404");
            return;
        }

        if (response?.data) {
            // Atualiza o estado com os dados do estabeleciment o
            setEstabelecimentoStatusMsg(response.data.statusMsg)
            setEstabelecimentoStatus(response.data.status);
            setEstabelecimentoLogo(response.data.logo);
            setEstabelecimentoBanner(response.data.banner);
            setEstabelecimentoValorMinimo(response.data.valorMinimo);
            setEstabelecimentoRua(response.data.rua);
            setEstabelecimentoNumero(response.data.numero);
            setEstabelecimentoBairro(response.data.bairro);
            setEstabelecimentoComplemento(response.data.complemento);
            setEstabelecimentoCidade(response.data.cidade);
            setEstabelecimentoUf(response.data.uf);
            setEstabelecimentoNome(response.data.nome);
            setCorBackground(response.data.corBackground);
            setCorTexto(response.data.corTexto);
            setValorTaxaEntrega(response.data.taxaEntrega);
            setTempoEntrega(response.data.entrega);
            setTempoRetirada(response.data.retirada);
            setEstabelecimentoWhatsapp(response.data.whatsapp);
            setEstabelecimentoFacebook(response.data.facebook);
            setEstabelecimentoInstagram(response.data.instagram);
            setEstabelecimentoEntregas(response.data.entregas);
            setEstabelecimentoHorarios(response.data.horarios);
            setEstabelecimentoPagamentos(response.data.pagamentos);
            setCorBackgroundHex(hexToRgb(response.data.corBackground));

            let jsonEstabelecimento = {
                estabelecimento: params.identificacao,
                rua: response.data.rua,
                numero: response.data.numero,
                bairro: response.data.bairro,
                complemento: response.data.complemento,
                cidade: response.data.cidade,
                uf: response.data.uf
            }

            localStorage.setItem(`@${params.identificacao}-DADOS`, JSON.stringify(jsonEstabelecimento));
        }
    }

    const ListarProdutos = async () => {
        const response = await api.get(`produto/listar?identificacao=${params.identificacao}`);
        if (response?.response?.data) {
            setEstabelecimentoProdutos([]);
        }

        if (response?.data) {
            setEstabelecimentoProdutos(response.data);
        }
    }

    const ValidarUsuario = async (numero, nome) => {
        if (numero && numero.length !== 15) {
            return;
        }

        try {
            const response = await api.post("cliente/validar?identificacao=" + params.identificacao, {
                nome: nome,
                numero: numero,
            });

            if (response?.data || response?.status === 200) {
                const jsonSessionUser = {
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    numero: response.data.numero,
                    enderecos: response.data.enderecos
                };

                localStorage.setItem(`@${params.identificacao}-SESSION`, response.data.session);
                localStorage.setItem(`@${params.identificacao}-USERDADOS`, JSON.stringify(jsonSessionUser));
                
                setIsAutenticaded(false);
                setIsVerifyed(response.data.perfil_verificado);
                setIsLogged(true);

            } 
            else {
                setIsLogged(false);
            }
        } catch (error) {
            alert('Erro');
            return;
        }
    }

    const Start = async () => {
        const jsonUsuario = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));
        if (jsonUsuario && Object.keys(jsonUsuario).length > 0) {
            await ValidarUsuario(jsonUsuario.numero, jsonUsuario.nome);
        } 
        else {
            setIsLogged(false);
        }

        await ListarDadosEstabelecimento();
        await ListarProdutos();
        setIsLoadingEstabelecimento(false);
    }

    function closeModalProdutoOpcionais() {
        setIsOpenModalProdutoOpcionais(false);
    }
    
    const offcanvasRef = useRef(null);

    function openModalProdutoOpcionais(produto) {
        if (JSON.stringify(produto).length > 0) {
            if (offcanvasRef.current) {
                offcanvasRef.current.click();
            }

            setProdutoClicado(produto);
            setIsOpenModalProdutoOpcionais(true);
        }
    }

    useEffect(() => {
        Start();
    }, []);

    const [scrollY, setScrollY] = useState(0);
    const [showNavbar, setShowNavbar] = useState(true);

    const handleScroll = () => {
        setScrollY(window.scrollY);
        setShowNavbar(window.scrollY <= 120);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const categoryRefs = useRef({});

    const scrollToCategory = (event) => {
        console.log(event.target.id)
        const selectedCategoryId = event.target.id;

        if(selectedCategoryId === 'top') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } 
    
        if(selectedCategoryId && categoryRefs.current[selectedCategoryId]) {
            const element = categoryRefs.current[selectedCategoryId];
            
            // Adiciona uma margem ao rolar
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // Alinha o topo do elemento com o topo da área visível
                inline: 'nearest'
            });
    
            // Adiciona uma margem extra com scrollTop
            window.scrollBy(0, -100); // Ajuste o valor conforme necessário para adicionar mais ou menos margem
        }
    };


    useEffect(() => {
        const handleScroll = () => {
            const categoryElements = Object.values(categoryRefs.current);
            let foundCategory = 'Voltar ao topo'; // Define 'top' como padrão
    
            for (const [id, element] of Object.entries(categoryRefs.current)) {
                if (element) {
                    const rect = element.getBoundingClientRect();
                    if (rect.top >= 0 && rect.top < window.innerHeight) {
                        foundCategory = id;
                        break;
                    }
                }
            }
    
            if (foundCategory !== selectedCategory) {
                setSelectedCategory(foundCategory);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [selectedCategory, categoryRefs.current]);

    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (e) => {
        scrollToCategory(e)
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    function OpenModalPesquisaProduto(){
        setAberto(true)
    }


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!e.target.closest('.dropdown')) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    
    function abrirWhatsapp() {
        let WppLink = document.createElement('a');
        let numero = estabelecimentoWhatsapp.replace(/[()\s-]/g, '');

        WppLink.target = '_blank';
        WppLink.href = 'https://api.whatsapp.com/send?phone=' + numero + '&text=Olá, tudo bem?';
        WppLink.click();
    }

    function abrirFacebook() {
        let link = document.createElement('a');
        let user = estabelecimentoFacebook;

        link.target = '_blank';
        link.href = 'https://www.facebook.com/' + user;
        link.click();
    }

    function abrirInstagram() {
        let link = document.createElement('a');
        let user = estabelecimentoFacebook;

        link.target = '_blank';
        link.href = 'https://www.instagram.com/' + user;
        link.click();
    }

    return isLoadingEstabelecimento ? 
        (
            <LoaderTelaToda />
        ) 
    : 
        (
            <div>

                <Helmet>
                    <link rel="icon" type="image/png" href={url_imagem + estabelecimentoLogo} sizes="16x16" />
                    <link rel="icon" type="image/png" href={url_imagem + estabelecimentoLogo} sizes="32x32" />
                    <link rel="icon" type="image/png" href={url_imagem + estabelecimentoLogo} sizes="96x96" />
                    <meta charset="UTF-8"></meta>
                    <title>{estabelecimentoNome}</title>
                    <meta name="robots" content="index, follow"></meta>
                    <link rel="canonical" href={`${url_site}${params.identificacao}`}></link>
                    
                    <meta property="og:title" content={`Cardápio do Restaurante ${params.identificacao}`}></meta>
                    <meta property="og:description" content={`Veja o cardápio completo do Restaurante ${estabelecimentoNome}.`}></meta>
                    <meta property="og:image" content={`${url_imagem}${estabelecimentoLogo}`}></meta>
                    <meta property="og:url" content={` ${url_site} ${params.identificacao}`}></meta>
                </Helmet>

                <NavBar
                    aberto={estabelecimentoStatus}
                    identificacao={params.identificacao}
                    valorPedidoMinimo={estabelecimentoValorMinimo}
                />

                <div>
                    <div className="offcanvas offcanvas-end offcanvas-fullscreen" tabIndex="-1" id="modal-pesquisa" aria-labelledby="modal-pesquisa">
                        <PesquisaProduto 
                            itens={estabelecimentoProdutos} 
                            openModalProduto={openModalProdutoOpcionais}
                        />
                            <button
                                type="button"
                                className="d-none"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                ref={offcanvasRef}
                                data-bs-target="modal-pesquisa"
                                >
                            </button>
                    </div>
                </div>

                {screenSize.width <= 768 && <MenuMobile pagina="home" />}
                {screenSize.width <= 768 && carrinho.length > 0 && <SacolaMobile />}

                {scrollY > 120 && (
                    <NavBar2
                        aberto={estabelecimentoStatus}
                        identificacao={params.identificacao}
                        valorPedidoMinimo={estabelecimentoValorMinimo}
                        categorias={estabelecimentoProdutos}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        scrollToCategory={scrollToCategory}
                        logo={estabelecimentoLogo}
                    />
                )}

                <ModalProdutoOpcionais
                    isOpen={isOpenModalProdutoOpcionais}
                    onRequestClose={closeModalProdutoOpcionais}
                    produto={produtoClicado}
                    aberto={estabelecimentoStatus}
                />

                <div className={screenSize.width <= 768 ? "container-fluid" : "container"}>
                    <div>
                        <Cabecalho
                            banner={estabelecimentoBanner}
                            logo={estabelecimentoLogo}
                            nome={estabelecimentoNome}
                            rua={estabelecimentoRua}
                            numero={estabelecimentoNumero}
                            complemento={estabelecimentoComplemento}
                            bairro={estabelecimentoBairro}
                            cidade={estabelecimentoCidade}
                            uf={estabelecimentoUf}
                            valorMinimo={estabelecimentoValorMinimo}
                            taxaEntrega={valorTaxaEntrega}
                            whatsapp={estabelecimentoWhatsapp}
                            instagram={estabelecimentoInstagram}
                            facebook={estabelecimentoFacebook}
                            tempoEntrega={tempoEntrega}
                            tempoRetirada={tempoRetirada}
                            status={estabelecimentoStatus}
                            statusMsg={estabelecimentoStatusMsg}
                            horarios={estabelecimentoHorarios}
                            pagamentos={estabelecimentoPagamentos}
                            entregas={estabelecimentoEntregas}
                        />

                        <div className="container-navbar mt-4">
                            <div className="navbar-opcoes d-flex justify-content-between align-items-center ">
                                <div className="dropdown-main" style={{width: '100%', marginRight: '10px'}} onClick={(e) => e.stopPropagation()}>
                                    <div className="dropdown-button" onClick={handleToggle}>
                                        <span>{selectedCategory}</span>
                                    </div>
                                    {isOpen && (
                                        <div className="dropdown-content">
                                            <div id="top no-select" onClick={handleSelect}>
                                                Voltar ao topo
                                            </div>
                                            {estabelecimentoProdutos.map((categoria) => (
                                                <div className="no-select" id={categoria.nome} key={categoria.id} onClick={(e) => handleSelect(e)}>
                                                    {categoria.nome}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <button data-bs-toggle="offcanvas" data-bs-target="#modal-pesquisa" onClick={(e) => OpenModalPesquisaProduto()} className="custom-button">
                                    <FaMagnifyingGlass />
                                </button>
                            </div>
                        </div>

                        <div>
                            <div className="produtos-area shadow-sm border-bottom">
                                <div className="row">
                                    {estabelecimentoProdutos.length > 0 ? (
                                        estabelecimentoProdutos.map((categoria) =>
                                            categoria.produtos.length > 0 ? (
                                                <div key={categoria.codigo} id={categoria.nome} ref={(el) => (categoryRefs.current[categoria.nome] = el)} className="observe">
                                                    <div className="mt-2 mb-2">
                                                        <span className="produto-categoria">{categoria.nome.toUpperCase()}</span>
                                                    </div>
                                                    <div className="row">
                                                        {categoria.produtos.map((produto) => (
                                                            <ProdutoCardapio
                                                                key={produto.codigo}
                                                                codigo={produto.codigo}
                                                                nome={produto.nome}
                                                                descricao={produto.descricao}
                                                                valor_venda={produto.valorVenda}
                                                                valor_vendapromo={produto.valorVendaPromocional}
                                                                controlaEstoque={produto.controlaEstoque}
                                                                estoque={produto.estoque}
                                                                imagem={produto.foto}
                                                                vegano={produto.vegano}
                                                                vegetariano={produto.vegetariano}
                                                                acucar={produto.acucar}
                                                                lactose={produto.lactose}
                                                                organico={produto.organico}
                                                                gelado={produto.gelado}
                                                                novidade={produto.novidade}
                                                                produtoClicado={produto}
                                                                abrirModal={openModalProdutoOpcionais}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : null
                                        )
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center text-center">
                                            <span className="nenhum-produto">Nenhum produto encontrado, volte mais tarde!</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className={screenSize.width <= 768 ? "footer mb-5" : "footer mt-5"} style={{backgroundColor: corBackground}}>
                    <div className="footer-bottom" style={{color: corTexto}}>
                        <img className="logo-navbar" src={url_imagem + estabelecimentoLogo} />
                        <span className="ms-3">&copy; {currentYear} Todos os direitos reservados {estabelecimentoNome}.</span>
                    </div>
                    
                    <div className="footer-redes">
                        {
                            estabelecimentoWhatsapp.length > 0 ? (
                                <button className="btn-footer-redessociais" style={{ backgroundColor: corBackground, color: corTexto }} onClick={abrirWhatsapp}>
                                    <FaWhatsapp fontSize={24}/>
                                </button>
                            )
                            :
                            null
                        }

                        {
                            estabelecimentoInstagram.length > 0 ? (
                                <button className="btn-footer-redessociais" style={{ backgroundColor: corBackground, color: corTexto }} onClick={abrirInstagram}>
                                    < FaInstagram fontSize={24}/> 
                                </button>
                            )
                                :
                                null
                        }

                        {
                            estabelecimentoFacebook.length > 0 ? (
                                <button className="btn-footer-redessociais" style={{ backgroundColor: corBackground, color: corTexto }} onClick={abrirFacebook}>
                                    < FaFacebookSquare fontSize={24}/>
                                </button>
                            )
                                :
                                null
                        }
                    </div>
                </footer>
            </div>
        );
}

export default Cardapio;
