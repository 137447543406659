import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

let URL_SERVER, url_banner, url_imagem, url_produto, url_site;

if(process.env.REACT_APP_AMBIENTE === "DESENVOLVIMENTO"){
    URL_SERVER = "http://localhost/seasonmenu-api/cardapio/";

    url_banner = "http://localhost/seasonmenu-api/public/banner_estabelecimento/";
    url_imagem = "http://localhost/seasonmenu-api/public/logo_estabelecimento/";
    url_produto = "http://localhost/seasonmenu-api/public/imagem_produto/";

    url_site = "http://localhost:3000/";
}

if(process.env.REACT_APP_AMBIENTE === "PRODUCAO"){
    URL_SERVER = "https://api.seasonmenu.com.br/cardapio";

    url_banner = "https://api.seasonmenu.com.br/public/banner_estabelecimento/";
    url_imagem = "https://api.seasonmenu.com.br/public/logo_estabelecimento/";
    url_produto = "https://api.seasonmenu.com.br/public/imagem_produto/";
    
    url_site = "https://app.seasonmenu.com.br/";
}

const api = axios.create({
    baseURL: URL_SERVER   
});

const pathname = window.location.pathname;
const primeiroValor = pathname.split('/')[1];

api.interceptors.request.use(req => {

    if (localStorage.getItem(`@${primeiroValor}-USERDADOS`)) {
        const jsonUser = JSON.parse(localStorage.getItem(`@${primeiroValor}-USERDADOS`));
        const token = jsonUser.sessionToken;

        req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
},
(err) => {
    console.log(err);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error?.response?.status === 401) {
        const estabelecimento = JSON.parse(localStorage.getItem(`@${primeiroValor}-DADOS`));
        const user = JSON.parse(localStorage.getItem(`@${primeiroValor}-USERDADOS`));

        api.post(`cliente/deslogar?identificacao=${primeiroValor}&codigo=${user.codigo}`)
        .then((response) =>{
            localStorage.removeItem(`@${primeiroValor}-SESSION`);
            localStorage.removeItem(`@${primeiroValor}-USERDADOS`);

            window.location.reload();

            toast.warning("Sessão expirada, faça login novamente!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        })
        .catch((err) =>{
            console.log(err)
        })

    }
    return error;
});

export {api, url_banner, url_imagem, url_produto, URL_SERVER, url_site}
