import React, { useState, useRef } from 'react';

function InputCodigo({ length = 6, onComplete }) {
  const [code, setCode] = useState(Array(length).fill(''));
  const inputs = useRef([]);

  const handleChange = (value, index) => {
    if (isNaN(value)) return; // Permite apenas números
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Avança para o próximo campo automaticamente
    if (value && index < length - 1) {
      inputs.current[index + 1].focus();
    }

    // Chama a função onComplete quando o código estiver completo
    if (newCode.every(digit => digit !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputs.current[index - 1].focus(); // Volta para o campo anterior se estiver vazio
    }
  };

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {code.map((digit, index) => (
        <input
          key={index}
          type="text"
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => inputs.current[index] = el}
          style={{
            width: '40px',
            height: '40px',
            textAlign: 'center',
            fontSize: '20px',
            border: '1px solid #ced4da',
            borderRadius: '4px',
          }}
        />
      ))}
    </div>
  );
};

export default InputCodigo;
