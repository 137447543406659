
import './style.css';
import { FaShoppingBag } from "react-icons/fa";

import { CarrinhoContext } from "../../contexts/carrinho-context.jsx";
import Carrinho from "../carrinho/index.jsx";

import { useContext } from 'react';

function SacolaMobile(){

    const {totalCarrinho} = useContext(CarrinhoContext);
    const {qtdeItensCarrinho} = useContext(CarrinhoContext);

    const {corBackground} = useContext(CarrinhoContext);
    const {corTexto} = useContext(CarrinhoContext);

    return <div className="cart-footer" style={{backgroundColor: corBackground, color: corTexto}}>
                <div className="cart-icon-container">
                    <FaShoppingBag className="cart-icon" size={24} style={{color: corTexto}} />
                    {qtdeItensCarrinho > 0 && <span className="item-count" style={{color: corTexto}}>{qtdeItensCarrinho}</span>}
                    <span className="cart-total">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCarrinho)}</span> 
                </div>

                <div className="view-cart" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" >
                    <span style={{color: corTexto}}>Ver Sacola</span>
                </div>
            </div>
}

export default SacolaMobile;