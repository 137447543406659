import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaBoxOpen } from "react-icons/fa";

import './style.css';
import ProdutoCardapio from "../produtos-cardapio/produto/index.jsx";

function PesquisaProduto(props) {
    const navigate = useNavigate();
    const { identificacao } = useParams();

    const [initialData, setInitialData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);

    useEffect(() =>{
        setSearchTerm('')
    }, [props.openModalProduto])

    useEffect(() => {
        setSearchTerm('')
        setInitialData(props.itens || []);
    }, [props.itens]);

    useEffect(() => {
        setSearchTerm('')
        setData(initialData);
    }, [initialData]);




    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterProducts = () => {
        if (searchTerm === '') {
            return [];
        }
        return data
            .map((category) => ({
                ...category,
                produtos: category.produtos.filter((product) =>
                    product.nome.toLowerCase().includes(searchTerm.toLowerCase())
                ),
            }))
            .filter((category) => category.produtos.length > 0);
    };

    const filterCategories = () => {
        if (searchTerm === '') {
            return [];
        }
        return data
            .map((category) => ({
                ...category,
                produtos: category.produtos.filter((product) =>
                    product.nome.toLowerCase().includes(searchTerm.toLowerCase())
                ),
            }))
            .filter((category) =>
                category.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
                category.produtos.length > 0
            );
    };

    const filteredProducts = filterProducts();
    const filteredCategories = filterCategories();
    const noResults = filteredProducts.length === 0 && filteredCategories.length === 0;

    return (
        <div className="h-100 container-pesquisa">
            <div className="d-flex align-items-center p-2">
                <div className="input-container">
                    <div className="input-button-wrapper">
                        <button
                            type="button"
                            className="btn-fechar"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            id="btn-fechar"
                            >
                            <FaArrowLeft size={16} color='#555' />
                        </button>
                        <div className="input-wrapper">
                            <span className="input-icon"><FaMagnifyingGlass size={16} color='#555' /></span>
                            <input
                                type="text"
                                id="custom-input"
                                className="custom-input"
                                placeholder="Buscar..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {searchTerm.length > 0 ? (
                <>
                    {noResults ? (
                            <div className="container-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-box" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M3 6l3 -1.5l3 1.5l3 -1.5l3 1.5l3 -1.5v12l-3 1.5l-3 -1.5l-3 1.5l-3 -1.5l-3 1.5z" />
                                </svg>
                                <span>Nenhum resultado encontrado</span>
                            </div>
                    ) : (
                        <>
                            {filteredProducts.map((categoria) => (
                                <div key={categoria.codigo} id={categoria.nome} className="container">
                                    <div className="">
                                        <span className="produto-categoria">{categoria.nome.toUpperCase()}</span>
                                    </div>
                                    <div className="row p-2">
                                        {categoria.produtos.map((produto) => (
                                            <ProdutoCardapio
                                                key={produto.codigo}
                                                codigo={produto.codigo}
                                                nome={produto.nome}
                                                descricao={produto.descricao}
                                                valor_venda={produto.valorVenda}
                                                valor_vendapromo={produto.valorVendaPromocional}
                                                controlaEstoque={produto.controlaEstoque}
                                                estoque={produto.estoque}
                                                imagem={produto.foto}
                                                pagina={'pesquisa-produto'}
                                                abrirModal={(e) => props.openModalProduto(produto)}
                                                
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}

                            {/* <h2>Categorias</h2>
                            {filteredCategories.map((category) => (
                                <div key={category.codigo}>
                                    <h3>{category.nome}</h3>

                                </div>
                            ))} */}
                        </>
                    )}
                </>
            ):
            <div className="container-icon">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
                <span>Nenhuma busca até o momento</span>
            </div>
        }
        </div>
    );
}

export default PesquisaProduto;
