import { createContext, useContext, useEffect, useState } from "react";
import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../api.js";
import { useParams } from "react-router-dom";

const CarrinhoContext = createContext();

function CarrinhoProvider(props) {
    const [estabelecimentoNome, setEstabelecimentoNome] = useState("");
    const [estabelecimentoWhatsapp, setEstabelecimentoWhatsapp] = useState("");

    const [tipoEntrega, setTipoEntrega] = useState(0);

    const [entrega, setEntrega] = useState("");
    const [retirada, setRetirada] = useState("");

    const [tempoEntrega, setTempoEntrega] = useState("");
    const [tempoRetirada, setTempoRetirada] = useState("");

    const [corBackgroundHex, setCorBackgroundHex] = useState("");
    const [corBackground, setCorBackground] = useState("");
    const [corTexto, setCorTexto] = useState("");

    const [carrinho, setCarrinho] = useState([]);

    const [qtdeItensCarrinho, setQtdeItensCarrinho] = useState(0);

    const [valorTaxaEntrega, setValorTaxaEntrega] = useState(0);

    const [subTotalCarrinho, setSubTotalCarrinho] = useState(0);
    const [totalCarrinho, setTotalCarrinho] = useState(0);

    const [taxaEntrega, setTaxaEntrega] = useState(0);
    
    const [isVerifyed, setIsVerifyed] = useState(false);
    const [isAutenticaded, setIsAutenticaded] = useState(false);
    const [isLogged, setIsLogged] = useState(false);

    const [loginNumero, setLoginNumero] = useState("");
    const [loginNome, setLoginNome] = useState("");
    const [usuarioEnderecos, setUsuarioEnderecos] = useState([]);

    const pathname = window.location.pathname;
    const primeiroValor = pathname.split('/')[1];


    useEffect(() => {
        const jsonCarrinho = localStorage.getItem(`@${primeiroValor}-SESSIONCART`);

        if (jsonCarrinho) {
            const dados = JSON.parse(jsonCarrinho);
            setCarrinho(dados.produtos);

            let soma = dados.produtos.reduce((a, b) => a + b.total * b.quantidade, 0);

            setSubTotalCarrinho(soma);
        }
    }, []);

    useEffect(() => {
        setTotalCarrinho(subTotalCarrinho);
    }, [subTotalCarrinho]);

    useEffect(() => {
        let soma = carrinho.reduce((a, b) => a + b.total * b.quantidade, 0);

        setSubTotalCarrinho(soma);
        setQtdeItensCarrinho(carrinho.length);
    }, [carrinho]);

    function addItemCarrinho(json) {
        const date = new Date();

        if (localStorage.getItem(`@${primeiroValor}-SESSIONCART`)) {
            const jsonCarrinho = JSON.parse(localStorage.getItem(`@${primeiroValor}-SESSIONCART`));

            if (date.toLocaleDateString() === jsonCarrinho.data) {

                setCarrinho([...carrinho, json]);
                salvarCarrinho([...carrinho, json]);
            } else {
                localStorage.removeItem(`@${primeiroValor}-SESSIONCART`);
                setCarrinho([]);
                salvarCarrinho([]);

                setCarrinho([json]);
                salvarCarrinho([json]);
            }
        } 
        else {
            setCarrinho([...carrinho, json]);
            salvarCarrinho([...carrinho, json]);
        }
    }

    function salvarCarrinho(produtos) {
        const date = new Date();

        let jsonCarrinho = {
            data: date.toLocaleDateString(),
            produtos,
        };


        localStorage.setItem(`@${primeiroValor}-SESSIONCART`, JSON.stringify(jsonCarrinho));
        //localStorage.setItem('sessionCart', JSON.stringify(jsonCarrinho));
    }

    function deletarItemCarrinho(uuid) {
        
        const novoCarrinho = carrinho.filter((item) => {
            return item.uuid != uuid;
        });

        setCarrinho(novoCarrinho);
        salvarCarrinho(novoCarrinho);
    }

    return (
        <CarrinhoContext.Provider
            value={{
                addItemCarrinho,
                deletarItemCarrinho,
                carrinho,
                setCarrinho,
                totalCarrinho,
                entrega,
                setEntrega,
                retirada,
                setRetirada,
                subTotalCarrinho,
                valorTaxaEntrega,
                setValorTaxaEntrega,
                tempoEntrega,
                setTempoEntrega,
                tempoRetirada,
                setTempoRetirada,
                corBackground,
                setCorBackground,
                corBackgroundHex,
                setCorBackgroundHex,    
                corTexto,
                setCorTexto,
                tipoEntrega,
                setTipoEntrega,
                estabelecimentoWhatsapp,
                setEstabelecimentoWhatsapp,
                estabelecimentoNome,
                setEstabelecimentoNome,
                qtdeItensCarrinho,
                taxaEntrega,
                setTaxaEntrega,
                isLogged, setIsLogged,
                usuarioEnderecos, setUsuarioEnderecos,
                loginNumero, setLoginNumero,
                loginNome, 
                setLoginNome,
                setIsVerifyed,
                isVerifyed,
                setIsAutenticaded,
                isAutenticaded
            }}
        >
            {props.children}
        </CarrinhoContext.Provider>
    );
}

export { CarrinhoContext, CarrinhoProvider };
