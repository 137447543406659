import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CarrinhoContext } from "../../contexts/carrinho-context.jsx"

import './style.css';

import LoaderTelaToda from "../../components/loader/LoaderTelaToda/index.jsx";
import AreaLogin from "../../components/login/AreaLogin/index.jsx";

import { api } from "../../api.js";

import { FaArrowLeftLong } from "react-icons/fa6";
import { FaBoxOpen } from "react-icons/fa";

import MenuMobile from "../../components/menu-mobile/index.jsx";
import AreaLoginVerify from "../../components/login/AreaLoginVerify/index.jsx";

function MeusPedidos() {

    const params = useParams();
    const navigate = useNavigate();

    const [isLoadingPedido, setIsLoadingPedido] = useState(false);
    const [pedidos, setPedidos] = useState([]);

    const { isLogged } = useContext(CarrinhoContext);
    const {isAutenticaded, setIsAutenticaded} = useContext(CarrinhoContext);

    const { setCorBackground , corBackground } = useContext(CarrinhoContext);
    const { setCorTexto, corTexto } = useContext(CarrinhoContext);

    const Start = async () => {

        setIsLoadingPedido(true);

        const responseestab = await api.get(`estabelecimento/listar?identificacao=${params.identificacao}`);

        if (responseestab?.response?.data) {
            navigate("/404");
            return;
        }

        if (responseestab?.data) {
            setCorBackground(responseestab.data.corBackground);
            setCorTexto(responseestab.data.corTexto);
        }

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));
        const codigoUsuario = jsonUsuario.codigo;

        const response = await api.get(`meuspedidos/listar?codigoCliente=${codigoUsuario}&identificacao=${params.identificacao}`);

        if( response?.response?.data ){
            navigate('/404');
        }

        if( response?.data ){
            setPedidos(response.data);
        }

        if(response.status === 401){
            navigate(`/${params.identificacao}`)
        }

        setIsLoadingPedido(false);
    }

    useEffect( () => {
        const verificaEstabelecimento = async () =>{
            const responseestab = await api.get(`estabelecimento/listar?identificacao=${params.identificacao}`);

            if (responseestab?.response?.data) {
                navigate("/404");
                return;
            }

            if (responseestab?.data) {
                setCorBackground(responseestab.data.corBackground);
                setCorTexto(responseestab.data.corTexto);
            }
        }

        verificaEstabelecimento();

        if(isLogged && isAutenticaded){
            Start();
        }
    }, []);

    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        !isLoadingPedido ? 
            (
                !isLogged ? 
                    (
                        <div className="main">
                            <AreaLogin />
                        </div>
                    ) 
                    : 
                    (
                        isLogged && !isAutenticaded ?
                        <div className="main">
                            <AreaLoginVerify area="meuspedidos" listarPedidos={Start} /> 
                        </div>
                        :
                        <div className="container">
                            {screenSize.width <= 768 && <MenuMobile pagina="meuspedidos" />}
                            <div className="meuspedidos-background">
                                <div className="meuspedidos-titulo d-flex align-items-center gap-3">
                                    <button className="btn-meuspedidos-voltar" onClick={ () => {navigate(`/${params.identificacao}`)} }><FaArrowLeftLong color={corBackground} /></button>
                                    <span className="titulo-pagina">Meus pedidos</span>
                                </div>

                                <div className="borda"></div>

                                {
                                pedidos.length > 0 ? (
                                    pedidos.map( (ped) =>{
                                        return (
                                            <div className="meuspedidos-pedido shadow p-3 mb-4 bg-body-tertiary rounded">
                                                <div className="d-flex flex-column">
                                                    <span className="meuspedidos-pedido-titulo">{ped.restaurante} - #{ped.codigo}</span>
                                                    <span className="meuspedidos-pedido-data">Pedido realizo em: 01/08/2024 às 22:37</span>
                                                </div>

                                                <div className="borda"></div>

                                                <div className="meuspedidos-pedido-informacoes d-flex align-items-center justify-content-between">
                                                    <span className="meuspedidos-pedido-valor">Valor Total: R$56,00</span>
                                                    <button className="btn-meuspedidos-maisinformacoes" onClick={() =>{navigate(`/${params.identificacao}/pedido/${ped.codigo}`)}} style={{backgroundColor: corBackground, color: corTexto}}>Ver detalhes</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                                : (
                                    <div className="meuspedidos-pedido-nenhumpedido">
                                        <FaBoxOpen className="icone-nenhum-pedido"/>
                                        <span className="nenhum-pedido">Você ainda não fez nenhum pedido no estabelecimento.</span>
                                    </div>
                                )
                                }
                                
                            </div>
                        </div>
                    )
            ) 
        : 
            (
                <LoaderTelaToda />
            )
    )

}

export default MeusPedidos;