import { AiFillCloseCircle } from "react-icons/ai";

import Modal from 'react-modal/lib/components/Modal';

import './style.css';

import AreaLogin from './AreaLogin/index.jsx';

Modal.setAppElement('#root');

function ModalLogin(props) {

    return <Modal isOpen={props.isOpen} 
                    onRequestClose={props.onRequestClose} 
                    overlayClassName={"modal-overlay"} 
                    className={"modal-background"} >
        
        <AreaLogin onRequestClose={props.onRequestClose} />

    </Modal>

}

export default ModalLogin;