import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

import CardItem from "./card-item/index.jsx";
import LoaderTelaToda from "../../components/loader/LoaderTelaToda/index.jsx";

import {api, url_imagem} from "../../api.js";

import './style.css';

function VisualizarPedido() {

    const params = useParams();
    const navigate = useNavigate();

    const [identificacao] = useState(params.identificacao);
    const [codigoPedido, setCodigoPedido] = useState(params.codigoPedido);

    const [isLoading, setIsLoading] = useState(false);

    const [estabelecimento, setEstabeleecimento] = useState({});
    const [cliente, setCliente] = useState({});
    const [pagamento, setPagamento] = useState({});
    const [tipoEntrega, setTipoEntrega] = useState({});
    const [endereco, setEndereco] = useState({});

    const [data, setData] = useState('');
    const [hora, setHora] = useState('');

    const [status, setStatus] = useState('');

    const [subTotal, setSubTotal] = useState(0);
    const [taxaEntrega, setTaxaEntrega] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const [total, setTotal] = useState(0);

    const [motivoCancelamento, setMotivoCancelamento] = useState("");
    const [dataCancelamento, setDataCancelamento] = useState("");
    const [horaCancelamento, setHoraCancelamento] = useState("");
    const [observacaoCancelamento, setObservacaoCancelamento] = useState("");


    const [itens, setItens] = useState([]);

    const [corBackground, setCorBackground] = useState('');
    const [corTexto, setCorTexto] = useState('');

    const ListarPedido = async () => {

        //pegar usuario
        const jsonUsuario = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
        const session = localStorage.getItem(`@${identificacao}-SESSION`);

        try {

            setIsLoading(true);

            const response = await api.get(`pedido/listar?codigoCliente=${jsonUsuario.codigo}&codigoPedido=${codigoPedido}&identificacao=${identificacao}&session=${session}`);

            if(response?.response?.data) {
                if(response?.response?.status === 404){
                    navigate('/404');
                    setIsLoading(false);
                }
                
            }

            if(response?.data) {
                setCodigoPedido(response.data.codigo);
                setData(response.data.data);
                setStatus(response.data.status);

                setData(new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(response.data.data)));
                setHora(new Intl.DateTimeFormat('pt-BR', { hour: 'numeric', minute: 'numeric' }).format(new Date(`1970-01-01T${response.data.hora}`)));

                setEstabeleecimento(response.data.estabelecimento);

                setCliente(response.data.cliente);

                setPagamento(response.data.pagamento);

                setTipoEntrega(response.data.tipoEntrega);
                setEndereco(response.data.endereco);

                setSubTotal(response.data.subtotal);
                setTaxaEntrega(response.data.taxaEntrega);
                setDesconto(response.data.desconto);
                setTotal(response.data.total);

                setItens(response.data.itens);

                setCorBackground(response.data.estabelecimento.corBackground);
                setCorTexto(response.data.estabelecimento.corTexto);

                setMotivoCancelamento(response.data.motivo_cancelamento)
                setDataCancelamento(response.data.data_cancelamento)
                setHoraCancelamento(response.data.hora_cancelamento)
                setObservacaoCancelamento(response.data.observacao_cancelamento)

                if(response.data.status === 'F' || response.data.status === 'C'){
                    stopInterval();
                }

                setIsLoading(false);
            }

        } catch (error) {
            navigate(`/${params.identificacao}`)
        }
    }

    const ExecutarAoIniciar = async () => {
        const jsonEstabelecimento = JSON.parse(localStorage.getItem(`@${identificacao}-DADOS`));
        const estabelecimento = jsonEstabelecimento.estabelecimento;

        let usuario = JSON.stringify(localStorage.getItem(`@${identificacao}-USERDADOS`))

        if(usuario === undefined || usuario === null){
            navigate(`/${identificacao}`);
        }

        if (estabelecimento !== identificacao) {
            navigate("/404");
            return;
        }
    };

    const intervalRef = useRef(null);

    useEffect(() => {
        ListarPedido();

        intervalRef.current = setInterval(() => {
            ListarPedido();
        }, 30000);

        return () => clearInterval(intervalRef.current);
    }, []);

    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    useEffect(() => {
        ExecutarAoIniciar();
    }, []);

    return isLoading ? <LoaderTelaToda /> : <div className="container">
        <div className="pedido d-flex flex-column">
            <div className="pedido-informacoes">
                <div className="d-flex flex-column justify-content-center">

                    <div className="d-flex align-items-center gap-2">
                        <span className="pedido-estabelecimento">{estabelecimento.nome}</span>
                        -
                        <span className="pedido-codigo">Pedido #{codigoPedido}</span>
                    </div>

                    <div className="borda"></div>

                    <div className="pedido-restaurante d-flex align-items-center">
                        <div className="pedido-restaurante-logo">
                            <img src={url_imagem + estabelecimento.logo} alt={'Logo estabelecimento'} />
                        </div>
                        <div className="d-flex flex-column">
                            <span className="pedido-restaurante-nome">{estabelecimento.nome}</span>
                            <span className="pedido-data">Pedido efetuado: {data} as {hora}.</span>
                        </div>
                    </div>


                </div>
            </div>

            <div className="borda"></div>

            <div className="pedido-status">
                <div className="container-pedido-status">
                    {
                        status === 'E' ? <>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo-ativo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido efetuado</span>
                                    </span>
                                </span>

                                <div>
                                    <span className="pedido-text-passo me-2">
                                        <span className="pedido-status-circulo-desativado"></span>
                                        <span className="pedido-texto-passo">
                                            <span>Pedido aceito</span>
                                        </span>
                                    </span>
                                </div>

                                <div>
                                    <span className="pedido-text-passo me-2">
                                        <span className="pedido-status-circulo-desativado"></span>
                                        <span className="pedido-texto-passo">
                                            <span>Pedido em produção</span>
                                        </span>
                                    </span>
                                </div>

                                <div>
                                    <span className="pedido-text-passo me-2">
                                        <span className="pedido-status-circulo-desativado"></span>
                                        <span className="pedido-texto-passo">
                                            <span>Saiu para entrega</span>
                                        </span>
                                    </span>
                                </div>
                        </>
                        :
                        status === 'A' ? <>
                            <span className="pedido-text-passo me-2">
                                <span className="pedido-status-circulo" style={{backgroundColor: corBackground}}></span>
                                <span className="pedido-texto-passo">
                                    <span>Pedido efetuado</span>
                                </span>
                            </span>

                            <div>
                                <span className="pedido-text-passo me-2">
                                <span className="pedido-status-circulo-ativo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido aceito</span>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo-desativado"></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido em produção</span>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo-desativado"></span>
                                    <span className="pedido-texto-passo">
                                        <span>Saiu para entrega</span>
                                    </span>
                                </span>
                            </div>
                        </>
                        :
                        status === 'P' ? <>

                            <span className="pedido-text-passo me-2">
                                <span className="pedido-status-circulo" style={{backgroundColor: corBackground}}></span>
                                <span className="pedido-texto-passo">
                                    <span>Pedido efetuado</span>
                                </span>
                            </span>

                            <div>
                                <span className="pedido-text-passo me-2">
                                <span className="pedido-status-circulo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido aceito</span>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo-ativo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido em produção</span>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo-desativado"></span>
                                    <span className="pedido-texto-passo">
                                        <span>Saiu para entrega</span>
                                    </span>
                                </span>
                            </div>
                        </>
                        :
                        status === 'S' ? <>

                            <span className="pedido-text-passo me-2">
                                <span className="pedido-status-circulo" style={{backgroundColor: corBackground}}></span>
                                <span className="pedido-texto-passo">
                                    <span>Pedido efetuado</span>
                                </span>
                            </span>

                            <div>
                                <span className="pedido-text-passo me-2">
                                <span className="pedido-status-circulo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido aceito</span>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Pedido em produção</span>
                                    </span>
                                </span>
                            </div>

                            <div>
                                <span className="pedido-text-passo me-2">
                                    <span className="pedido-status-circulo-ativo" style={{backgroundColor: corBackground}}></span>
                                    <span className="pedido-texto-passo">
                                        <span>Saiu para entrega</span>
                                    </span>
                                </span>
                            </div>
                        </>
                        :
                        null 
                    }
                </div>
                {
                    status === 'C' ?
                    <div className="alert alert-danger" role="alert">
                        <span>Infelizmente seu pedido foi cancelado!</span> <br/>
                        <span>CANCELADO EM: {dataCancelamento} ÀS {horaCancelamento}</span> <br/>
                        <span>MOTIVO DO CANCELAMENTO: {motivoCancelamento}</span> 
                    </div>
                    :
                    ''
                }
            </div>

            <div className="borda"></div>

            <div className="pedido-cliente d-flex flex-column gap-2">
            <span className="pedido-titulo">Quem irá receber o pedido:</span>
            <span className="pedido-cliente-informacoes">{cliente.nome} - {cliente.numero}</span>
        </div>
        
        <div className="borda"></div>

        <div className="pedido-formapagamento d-flex flex-column gap-2">
            <span className="pedido-titulo">Forma de pagamento:</span>
            
            
            <div className="d-flex flex-column">
                <div className="pedido-pagamento d-flex align-items-center">
                    <img width="22" height="16" alt="Tipo pagamento" src={pagamento.imagem} />
                    <span className="pedido-informacao">{pagamento.nome}</span>
                </div>
                <div className="pedido-pagamento-troco">
                {
                    pagamento.nome === 'Dinheiro' && pagamento.troco > 0 ? <span>Troco para: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamento.troco)}</span> : null
                }
                </div>
            </div>
            
        </div>

        <div className="borda"></div>

        {
            tipoEntrega === 1 ? <div className="pedido-entrega d-flex flex-column gap-2">
                <span className="pedido-titulo">Endereço de entrega:</span>
                <div className="d-flex align-items-center">
                    <span className="pedido-endereco-entrega">
                        {endereco.rua} - {endereco.numero}, {endereco.bairro} {endereco.complemento.length > 0 ? ' - ' + endereco.complemento : null} - {endereco.cidade} - {endereco.uf}
                    </span>
                </div>
            </div>
                :
                tipoEntrega === 2 ? <div className="pedido-entrega d-flex flex-column">
                    <span className="pedido-titulo">Endereço de retirada:</span>
                    <div className="pedido-entrega-endereco d-flex align-items-center mt-2">
                        <span className="pedido-endereco-entrega">
                            {endereco.rua} - {endereco.numero}, {endereco.bairro} {endereco.complemento.length > 0 ? ' - ' + endereco.complemento : null} - {endereco.cidade} - {endereco.uf}
                        </span>

                        <button className="btn-vernomapa" style={{ backgroundColor: corBackground, color: corTexto }}>
                            Ver no mapa
                        </button>
                    </div>
                </div>
                    :
                    null
        }

        <div className="borda"></div>

        <div className="pedido-itens d-flex flex-column gap-2">
            <div className="pedido-titulo">
                Itens
            </div>
            {
                itens.map((item, index) => {
                    return (
                        <CardItem
                            key={index}
                            nome={item.nome}
                            quantidade={item.quantidade}
                            valor={item.preco}
                            total={item.total}
                            obs={item.obs}
                            foto={item.foto}
                            opcionais={item.opcionais} />
                    )
                })
            }
        </div>


        <div className="borda"></div>

        <div className="d-flex flex-column pedido-totais">
            <div className="pedido-valores d-flex justify-content-between">
                <span>Subtotal: </span>
                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(subTotal)}</span>
            </div>
            <div className="pedido-valores d-flex justify-content-between">
                <span>Taxa de entrega: </span>
                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(taxaEntrega)}</span>
            </div>
            <div className="pedido-valores d-flex justify-content-between">
                <span>Desconto: </span>
                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(desconto)}</span>
            </div>
            <div className="pedido-total d-flex justify-content-between">
                <span>Total: </span>
                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</span>
            </div>

        </div>

        </div>

        

    </div>
}

export default VisualizarPedido;