import React from "react";
import { useContext } from "react";
import { useState } from "react";

import { url_banner, url_imagem } from "../../api.js";

import "./style.css";

import ModalInformacoes from "./modalInformacoes/index.jsx";
import { CarrinhoContext } from "../../contexts/carrinho-context";

import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";

import './style.css';
import { TbBackground } from "react-icons/tb";

function Cabecalho(props) {
    const [isOpenModalInformacoes, setIsOpenModalInformacoes] = useState(false);

    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);

    function abrirWhatsapp() {
        let WppLink = document.createElement('a');
        let numero = props.whatsapp.replace(/[()\s-]/g, '');

        WppLink.target = '_blank';
        WppLink.href = 'https://api.whatsapp.com/send?phone=' + numero + '&text=Olá, tudo bem?';
        WppLink.click();
    }

    function abrirFacebook() {
        let link = document.createElement('a');
        let user = props.facebook;

        link.target = '_blank';
        link.href = 'https://www.facebook.com/' + user;
        link.click();
    }

    function abrirInstagram() {
        let link = document.createElement('a');
        let user = props.facebook;

        link.target = '_blank';
        link.href = 'https://www.instagram.com/' + user;
        link.click();
    }

    function openModalInformacoes() {
        setIsOpenModalInformacoes(true);
    }

    function closeModalInformacoes() {
        setIsOpenModalInformacoes(false);
    }



    return <div className="cabecalho-container">

        <ModalInformacoes isOpen={isOpenModalInformacoes}
            onRequestClose={closeModalInformacoes}
            abrirWhatsapp={abrirWhatsapp}
            abrirInstagram={abrirInstagram}
            abrirFacebook={abrirFacebook}
            horarios={props.horarios}
            pagamentos={props.pagamentos}
            rua={props.rua}
            bairro={props.bairro}
            numero={props.numero}
            complemento={props.complemento}
            cidade={props.cidade}
            uf={props.uf}
            tempoEntrega={props.tempoEntrega}
            tempoRetirada={props.tempoRetirada}
            taxaEntrega={props.taxaEntrega}
            whatsapp={props.whatsapp}
            instagram={props.instagram}
            facebook={props.facebook}
            entregas={props.entregas} />

        <div className="cabecalho">
            <div className="cabecalho-banner">
                <img src={ url_banner + props.banner } alt="Banner" />
            </div>
        </div>

        <div className="informacoes-estabelecimento border-bottom shadow-sm">
            <div className="estabelecimento-logo">
                <img src={ url_imagem + props.logo} />
            </div>

            <div className="estabelecimento-dados">
                <div className="estabelecimento-informacoes">
                    <div className="estabelecimento-nome">
                        {props.nome}
                    </div>


                    <div className="estabelecimento-status gap-1">
                        
                        <div className="estabelecimento-status-bola"
                            style={{backgroundColor: props.status === 'aberto' ? 'green' : 'red'}}>
                        </div>
                        
                        <span>{props.statusMsg}</span>
                    </div>
                </div>

                <div className="estabelecimento-informacoes-extra gap-1">
                    <span className="estabelecimento-informacoes-extra-endereco"><FaMapMarkerAlt /> {props.rua}, {props.numero} {props.complemento != null ? ', ' + props.complemento : null} - {props.cidade} - {props.uf}</span>
                    <span className="estabelecimento-informacoes-extra-endereco-mobile"><FaMapMarkerAlt /> {props.cidade} </span>
                    <button className="mais-informacoes fw-bold" onClick={openModalInformacoes}>
                        Saiba mais
                    </button>
                </div>

            </div>
        </div>
    </div>
}

export default Cabecalho;