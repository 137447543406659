import React from 'react';

import { IoHomeOutline } from "react-icons/io5";
import { IoHomeSharp } from "react-icons/io5";

import { CiShoppingCart } from 'react-icons/ci';
import { IoCart } from "react-icons/io5";

import { CiUser, } from 'react-icons/ci';
import { FaUser } from "react-icons/fa";

import './style.css';
import { useNavigate, useParams } from 'react-router-dom';

const MenuMobile = ({pagina}) => {

    const { identificacao } = useParams()
    const navigate = useNavigate();

    return (
        <div className="bottom-nav">
            <button onClick={() => navigate(`/${identificacao}`)} className="nav-button">
                <div className={pagina === "home" ? "button-content selected" : "button-content"}>
                    {pagina === "home" ? <IoHomeSharp size={24} /> : <IoHomeOutline size={24} />}
                    <span>Início</span>
                </div>
            </button>
            <button onClick={() => navigate(`/${identificacao}/meuspedidos`)} className="nav-button">
                <div className={pagina === "meuspedidos" ? "button-content selected" : "button-content"}>
                    {pagina === "meuspedidos" ? <IoCart size={24} />: <CiShoppingCart size={24} />}
                    <span className={pagina === "meuspedidos" ? "fw-bold" : ""}>Meus pedidos</span>
                </div>
            </button>
            <button onClick={(e) => navigate(`/${identificacao}/perfil`)} className="nav-button">
                <div className="button-content">

                    {pagina === "perfil" ? <FaUser size={24} />: <CiUser size={24} />}
                    <span className={pagina === "perfil" ? "fw-bold" : ""}>Perfil</span>
                </div>
            </button>
        </div>
    );
};

export default MenuMobile;
