import './style.css';

import refrigerante from '../../../assets/images/soda.png';
import hamburguer from '../../../assets/images/hamburguer.png';
import garfofaca from '../../../assets/images/garfo-e-faca.png';

function LoaderTelaToda(){
    return <div className="loader d-flex flex-column justify-content-center">
        <div className="d-flex">
            <div className="loading-garfo me-4">
                <img src={garfofaca} alt="Garfo faca" />
            </div>
            <div className="loading-refrigerante me-4">
                <img src={refrigerante} alt="Refrigerante" />
            </div>
            <div className="loading-hamburguer me-4">
                <img src={hamburguer} alt="Hamburguer" />
            </div>
        </div>
        <div className="text-center mt-4">
            Carregando informações...
        </div>
    </div>
}

export default LoaderTelaToda;