import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { formatar } from "../../react-format.js";

import { FaFloppyDisk } from "react-icons/fa6";

import { CarrinhoContext } from "../../contexts/carrinho-context.jsx"

import './style.css';

import LoaderTelaToda from "../../components/loader/LoaderTelaToda/index.jsx";
import AreaLogin from "../../components/login/AreaLogin/index.jsx";

import { api } from "../../api.js";

import { FaArrowLeftLong } from "react-icons/fa6";
import MenuMobile from "../../components/menu-mobile/index.jsx";
import AreaLoginVerify from "../../components/login/AreaLoginVerify/index.jsx";

function Perfil() {

    const params = useParams();
    const navigate = useNavigate();

    const {isAutenticaded, setIsAutenticaded} = useContext(CarrinhoContext);

    const [isLoadingPedido, setIsLoadingPedido] = useState(false);

    const { isLogged } = useContext(CarrinhoContext);
    const { setCorBackground , corBackground } = useContext(CarrinhoContext);
    const { setCorTexto, corTexto } = useContext(CarrinhoContext);

    const [isLoad, setIsLoad] = useState(false);

    const [codigo, setCodigo] = useState("");
    const [nome, setNome] = useState("");
    const [telefone, setTelefone] = useState("");
    const [aniversario, setAniversario] = useState("");

    const [senha, setSenha] = useState("");
    const [senha2, setSenha2] = useState("");

    const Start = async () => {

        setIsLoadingPedido(true);

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));

        let session = localStorage.getItem(`@${params.identificacao}-SESSION`);
        const codigoUsuario = jsonUsuario.codigo;

        const response = await api.get(`usuario/perfil/listar?codigoUsuario=${codigoUsuario}&identificacao=${params.identificacao}&session=${session}`);

        if( response?.response?.data ){
            navigate('/404');
        }

        if( response?.data ){
            setCodigo(response.data.codigo);
            setNome(response.data.nome);
            setTelefone(response.data.numero)
            setAniversario(response.data.aniversario)

            setIsLoadingPedido(false);
        }

        if(response.status === 401){
            navigate(`/${params.identificacao}`)
        }
    }

    const salvarPerfil = async (e) => {
        e.preventDefault();
        setIsLoad(true);

        if(senha !== senha2){
            return;
        }

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${params.identificacao}-USERDADOS`));
        let session = localStorage.getItem(`@${params.identificacao}-SESSION`);
        const codigoUsuario = jsonUsuario.codigo;
        jsonUsuario.nome = nome
        jsonUsuario.numero = telefone

        const response = await api.patch(`usuario/perfil/salvar?codigoUsuario=${codigoUsuario}&identificacao=${params.identificacao}&session=${session}`,{
            nome: nome,
            numero: telefone,
            aniversario: aniversario,
            senha: senha
        });

        if( response?.data){
            localStorage.setItem(`@${params.identificacao}-USERDADOS`, JSON.stringify(jsonUsuario))

            setIsLoad(false);
        }
    }

    useEffect( () => {
        const verificaEstabelecimento = async () =>{
            const responseestab = await api.get(`estabelecimento/listar?identificacao=${params.identificacao}`);
    
            if (responseestab?.response?.data) {
                navigate("/404");
                return;
            }

            if (responseestab?.data) {
                setCorBackground(responseestab.data.corBackground);
                setCorTexto(responseestab.data.corTexto);
            }

            if(responseestab.status === 401){
                navigate(`/${params.identificacao}`)
            }
        }
        
        verificaEstabelecimento();

        if(isLogged && isAutenticaded){
            Start();
        }
    }, []);

    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function MascaraNumero(e) {
        let obj = formatar(e.target, "CELULAR");

        setTelefone(obj.texto);
    }

    return (
        !isLoadingPedido ?
        (
            !isLogged ? (
                <div className="main">
                    <AreaLogin />
                </div>
            )
            : 
            (
                isLogged && !isAutenticaded ?
                    <div className="main">
                        <AreaLoginVerify area="perfil" listarDadosPerfil={Start} /> 
                    </div>
                    :
                    <div className="container">
                        {screenSize.width <= 768 && <MenuMobile pagina="perfil" />}
                        
                        <div className="meuspedidos-background">
                            <div className="meuspedidos-titulo d-flex align-items-center gap-3">
                                <button className="btn-meuspedidos-voltar" onClick={ () => {navigate(`/${params.identificacao}`)} }><FaArrowLeftLong color={corBackground} /></button>
                                <span>Meus perfil</span>
                            </div>

                            <div className="borda"></div>

                            <div className="checkout-area-login d-flex flex-column gap-4">
                                <div className="d-flex flex-column">
                                    <span>Número whatsapp</span>
                                    <input className="input-w100" inputMode="numeric" value={telefone} onChange={MascaraNumero}  type="text" id="telefone" />
                                </div>

                                <div className="d-flex flex-column">
                                    <span>Nome</span>
                                    <input className="input-w100" value={nome} onChange={e => {setNome(e.target.value);}} type="text" id="nome" />
                                </div>

                                <div className="d-flex flex-column">
                                    <span>Aniversário</span>
                                    <input className="input-w100" value={aniversario} onChange={e => {setAniversario(e.target.value);}} type="date" id="aniversario" />
                                </div>
                            </div>

                            <div className="row" style={{marginBottom: "20px"}}>
                                <div className="col-lg-6 com-sm-12 d-flex flex-column mb-3">
                                    <span>Senha</span>
                                    <input className="input-w100" value={senha} onChange={(e) => setSenha(e.target.value)}  type="password" id="senha" />
                                </div>

                                <div className="col-lg-6 com-sm-12 d-flex flex-column mb-3">
                                    <span>Confirme a  senha</span>
                                    <input className="input-w100" value={senha2} onChange={e => {setSenha2(e.target.value);}} type="password" id="confirmasenha" />
                                </div>
                            </div>
                            
                            <div>
                                <button onClick={(e) => salvarPerfil(e)} className="btn-salvar" style={{backgroundColor: `${corBackground}`, color: `${corTexto}`}}>
                                    {
                                        isLoad ? 
                                        <div>
                                            <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                            <span className="ms-2">Salvando...</span>
                                        </div>
                                        :
                                        <span>
                                            <FaFloppyDisk className="me-2" size={16}/>
                                            Salvar
                                        </span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
            )
        ) 
        : 
        (
            <LoaderTelaToda />
        )
    )

}

export default Perfil;