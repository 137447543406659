import React, { useContext, useEffect, useState } from 'react';

import Modal from 'react-modal/lib/components/Modal';

import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../../../api";

import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';
import { AiFillCloseCircle } from "react-icons/ai";
import "./style.css";

import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { BiBorderBottom } from 'react-icons/bi';


Modal.setAppElement('#root');

function ModalInformacoes(props) {

    const [activeTab, setActiveTab] = useState(1);

    const { identificacao } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground } = useContext(CarrinhoContext);

    const [horarios, setHorarios] = useState([]);
    const [pagamentos, setPagamentos] = useState([]);
    const [entregas, setEntregas] = useState([]);

    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');

    const [diaAtual, setDiaAtual] = useState(0);

    const [sessao, setSessao] = useState(1);

    function formatAddress(address) {
        return address.trim().replace(/\s+/g, '+');
    }

    function abrirMapa() {

        let endereco = props.rua + ' ' + props.numero + ' ' + props.cidade + ' ' + props.uf
        let endereco_formatado = formatAddress(endereco)

        let mapsLink = document.createElement('a');
        mapsLink.target = '_blank';
        mapsLink.href = 'https://www.google.com/maps?zoom=30&daddr='+endereco_formatado;
        mapsLink.click();
    }

    function getCurrentDay() {
        const today = new Date();
        return today.getDay();
    }

    useEffect(() => {
        setHorarios(props.horarios);
        setPagamentos(props.pagamentos);
        setEntregas(props.entregas);

        const dia = getCurrentDay();
        setDiaAtual(dia);

        setRua(props.rua);
        setNumero(props.numero);
        setBairro(props.bairro);
        setComplemento(props.complemento);
        setCidade(props.cidade);
        setUf(props.uf);
    }, []);

    return <Modal isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        overlayClassName={"modal-overlay"}
        className={"modal-background"} >

        <div className="sobre-estabelecimento-informacoes">

            <div className="area-fechar-modal d-flex justify-content-end">
                <button onClick={(e) => props.onRequestClose()} type="button" className="react-modal-close">
                    <AiFillCloseCircle size={32} color='#555' />
                </button>
            </div>

            <div className="informacoes-opcoes d-flex justify-content-around">
                <button className="btn-opcoes"
                    onClick={() => { setSessao(1) }}
                    style={sessao === 1 ? { borderBottom: `2px solid ${corBackground}` } : null}> Informacoes </button>
                <button className="btn-opcoes"
                    onClick={() => { setSessao(2) }}
                    style={sessao === 2 ? { borderBottom: `2px solid ${corBackground}` } : null}> Horários </button>
                <button className="btn-opcoes"
                    onClick={() => { setSessao(3) }}
                    style={sessao === 3 ? { borderBottom: `2px solid ${corBackground}` } : null}> Pagamentos </button>
            </div>
            <div className="area-informacoes">

                {
                    sessao === 1 ? <div className="area-informacoes-sobre">

                        <div className="sobre-endereco">
                            <span className="sobre-endereco-titulo">Endereço</span>
                            <span>{`${rua}, ${numero} - ${bairro} ${complemento != null ? complemento : null}`}</span>
                            <span>São Joaquim da Barra - SP</span>

                            <div className="sobre-endereco-vernomapa">
                                <button className="btn-vernomapa" onClick={abrirMapa} style={{ backgroundColor: corBackground, color: corTexto }}>
                                    < FiMapPin /> Ver no mapa
                                </button>
                            </div>

                        </div>

                        <div className="borda"></div>

                        <div className="sobre-formadeentrega">
                            <span className="sobre-formadeentrega-titulo">Formas de entrega</span>
                            {
                                entregas.length > 0 ? (
                                    entregas.map((entrega) => (
                                        entrega.nome === 'Delivery' ? (
                                            <div className="forma-entrega" key={entrega.codigo}>
                                                <span className="forma-entrega-titulo">{entrega.nome}</span>
                                                <span> - Tempo de entrega: {props.tempoEntrega} min</span>
                                                <span> - Taxa de entrega: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.taxaEntrega)}</span>
                                            </div>
                                        ) :
                                            entrega.nome === 'Retirada' ? (
                                                <div className="forma-entrega" key={entrega.codigo}>
                                                    <span className="forma-entrega-titulo">{entrega.nome}</span>
                                                    <span> - Tempo de retirada: {props.tempoRetirada} min</span>
                                                </div>
                                            ) :
                                                <div className="forma-entrega" key={entrega.codigo}>
                                                    <span className="forma-entrega-titulo">{entrega.nome}</span>
                                                    <span> - A combinar</span>
                                                </div>
                                    ))
                                ) : (
                                    <span>Nenhum tipo de entrega cadastrado.</span>
                                )
                            }

                        </div>

                        <div className="borda"></div>

                        <div className="sobre-contato gap-1">

                            <span className="sobre-contato-titulo">Contato</span>

                            <div className="sobre-contato-sociais d-flex gap-2">
                                {
                                    props.whatsapp.length > 0 ? (
                                        <button className="btn-redessociais" style={{ backgroundColor: corBackground, color: corTexto }} onClick={props.abrirWhatsapp}>
                                            <FaWhatsapp /> WhatsApp
                                        </button>
                                    )
                                        :
                                        null
                                }

                                {
                                    props.instagram.length > 0 ? (
                                        <button className="btn-redessociais" style={{ backgroundColor: corBackground, color: corTexto }} onClick={props.abrirInstagram}>
                                            < FaInstagram /> Instagram
                                        </button>
                                    )
                                        :
                                        null
                                }

                                {
                                    props.facebook.length > 0 ? (
                                        <button className="btn-redessociais" style={{ backgroundColor: corBackground, color: corTexto }} onClick={props.abrirFacebook}>
                                            < FaFacebookSquare /> Facebook
                                        </button>
                                    )
                                        :
                                        null
                                }

                            </div>
                        </div>

                    </div> :
                        sessao === 2 ? <div>
                            <div className="sobre-horarios">
                                {
                                    horarios.length > 0 ? horarios.map((horario, index) => {
                                        return <div className={index === diaAtual ? "sobre-horario-funcionamento hoje gap-2" : "sobre-horario-funcionamento gap-2"} key={horario.dia}>
                                                    <span className="sobre-horario-dia">{horario.dia}:</span>

                                                    <div className="horario-funcionamento gap-2">
                                                        <div className="d-flex gap-1">
                                                            <span>
                                                                {new Intl.DateTimeFormat('pt-BR', { hour: 'numeric', minute: 'numeric' }).format(new Date(`1970-01-01T${horario.abertura}`))}
                                                            </span>
                                                            às
                                                            <span>
                                                                {new Intl.DateTimeFormat('pt-BR', { hour: 'numeric', minute: 'numeric' }).format(new Date(`1970-01-01T${horario.fechamento}`))}
                                                            </span>
                                                        </div>
                                                        
                                                        {
                                                            horario.abertura2 === '00:00:00' && horario.fechamento2 === '00:00:00' ?
                                                            ''
                                                            :
                                                            <div className="d-flex gap-1">
                                                                <span>
                                                                    {new Intl.DateTimeFormat('pt-BR', { hour: 'numeric', minute: 'numeric' }).format(new Date(`1970-01-01T${horario.abertura2}`))}
                                                                </span>
                                                                às
                                                                <span>
                                                                    {new Intl.DateTimeFormat('pt-BR', { hour: 'numeric', minute: 'numeric' }).format(new Date(`1970-01-01T${horario.fechamento2}`))}
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                    })
                                        :
                                        <span>Nenhum horário cadastrado.</span>
                                }
                            </div>
                        </div> :
                            sessao === 3 ? <div>
                                <div className="sobre-pagamentos">
                                    <span className="sobre-pagamentos-titulo">Formas de pagamento</span>

                                    <div className="row">
                                        {
                                            pagamentos.length > 0 ? pagamentos.map((pagamento) => {
                                                return <div className="col-lg-6 col-md-6 col-sm-12 p-2" key={pagamento.codigo}>
                                                    <div className="sobre-formapagamento d-flex align-items-center gap-2">
                                                        <img width={32} height={22} src={pagamento.imagem} />
                                                        <span>{pagamento.nome}</span>
                                                    </div>
                                                </div>
                                            })
                                                :
                                                null
                                        }
                                    </div>

                                </div>

                            </div>
                                :
                                null

                }

            </div>

        </div>

    </Modal>


}

export default ModalInformacoes;