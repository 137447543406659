import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { api } from "../../../api.js";
import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';
import { formatar } from "../../../react-format.js";

import './style.css';

function AreaLogin(props) {

    const params = useParams();
    
    const [identificacao, setIdentificacao] = useState(params.identificacao);

    const [isLoginLoading, setIsLoginLoading] = useState(false);

    const {isLogged, setIsLogged} = useContext(CarrinhoContext);
    const {isVerifyed, setIsVerifyed} = useContext(CarrinhoContext);
    const {isAutenticaded, setIsAutenticaded} = useContext(CarrinhoContext);
    const {usuarioEnderecos, setUsuarioEnderecos} = useContext(CarrinhoContext);
    const {loginNome, setLoginNome} = useContext(CarrinhoContext);
    const {loginNumero, setLoginNumero} = useContext(CarrinhoContext);
    
    const { corBackground } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);

    const ValidarUsuario = async () => {

        if (loginNumero.length !== 15) {
            return;
        }

        setIsLoginLoading(true);

        try {
            const response = await api.post("cliente/validar?identificacao=" + identificacao, {
                nome: loginNome,
                numero: loginNumero,
            });
            
            if(response?.response?.data) {
                if( response?.response?.data.message === 'Preencha o nome' ){
                    setIsLoginLoading(false);
                    return;
                }
            }

            if(response?.data){
                if(loginNome.length === 0 ){
                    setLoginNome(response.data.nome);
                    setLoginNumero(response.data.numero);

                    setIsLoginLoading(false);
                    return;
                }

                setLoginNome(response.data.nome);
                setLoginNumero(response.data.numero);
                setUsuarioEnderecos(response.data.enderecos);

                const jsonSessionUser = {
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    numero: response.data.numero,
                    enderecos: response.data.enderecos
                };

                localStorage.setItem(`@${identificacao}-SESSION`, response.data.session);
                localStorage.setItem(`@${identificacao}-USERDADOS`, JSON.stringify(jsonSessionUser));
                
                setIsVerifyed(response.data.perfil_verificado);
                setIsLogged(true);
                
                setIsLoginLoading(false);
                
                if (typeof props.onRequestClose === 'function') {
                    props.onRequestClose();
                }
            }
        } catch (error) {
            alert('Erro' + error);
            return;
        }
    };

    function MascaraNumero(e) {
        let obj = formatar(e.target, "CELULAR");

        setLoginNumero(obj.texto);
        setLoginNome("");
    }

    useEffect( () => {
        const LoginAutomatico = async () => {
            const jsonUser = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
            if( (jsonUser) && (Object.keys(jsonUser).length > 0) ){
                const numero = jsonUser.numero;
                const nome = jsonUser.nome;

                setLoginNome(nome);
                setLoginNumero(numero);

                ValidarUsuario();
            }
        }
        
        LoginAutomatico();
    }, []);

    return <div className="container">
            <div className="checkout-login rounded">
                <div className="checkout-login-titulo text-center">
                    <span className="">Informe os dados abaixo, caso precisemos entrar em contato com você.</span>
                </div>

                <div className="checkout-area-login d-flex flex-column gap-4">
                    <div className="d-flex flex-column">
                        <span>Número whatsapp</span>
                        <input className="input-w100" inputMode="numeric" value={loginNumero} onChange={MascaraNumero} onBlur={(e) => {ValidarUsuario()}} type="text" id="numero-login" />
                    </div>

                    <div className="d-flex flex-column">
                        <span className={loginNumero.length === 15 ? "" : "span-disabled"}>Nome</span>
                        <input className="input-w100" disabled={loginNumero.length === 15 && !isLoginLoading ? false : true} value={loginNome} onChange={e => {setLoginNome(e.target.value);}} type="text" id="nome-login" />
                    </div>
                </div>

                <button
                    className="btn-acessar"
                    onClick={e => { ValidarUsuario(); }}
                    style={{ backgroundColor: corBackground, color: corTexto }}
                    disabled={loginNumero.length !== 15 ? true : loginNome.length < 4 ? true : false}>
                    {isLoginLoading === true ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                        </div>
                    ) : (
                        <span>{loginNumero.length !== 15 ? <span>Insira seu número</span> : loginNome.length < 2 ? <span>Insira seu nome</span> : <span>Entrar</span>}</span>
                    )}
                </button>
            </div>
        </div>
}

export default AreaLogin;