import { useState } from 'react';
import { useContext } from 'react';

import { CarrinhoContext } from '../../../contexts/carrinho-context';

import './style.css';

function TipoPagamento(props) {

    const {corBackground } = useContext(CarrinhoContext);
    const {corTexto } = useContext(CarrinhoContext);

    return <div className="col-lg-6 col-md-6">
        <div className="tipo-pagamento"
        style={ props.codigo === props.PagamentoSelecionado ? {border:`1px solid ${corBackground}`}: {border:`1px solid #ccc`}}
        onClick={(e) => { props.SelecionarTipoPagamento(props.codigo, props.nome) }} >
            <div className="d-flex align-items-center">
                <img className="ms-2" width={22} height={16} src={props.imagem} alt="Tipo pagamento" />
                <label className="pagamento-nome" style={props.codigo === props.PagamentoSelecionado ? {color: corBackground}:{color: '#333'} }>{props.nome}</label>
            </div>
        </div>
    </div>

}

export default TipoPagamento;