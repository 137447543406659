import { useContext } from 'react';
import { CarrinhoContext } from '../../contexts/carrinho-context.jsx';

import "./style.css";

import { v4 as uuidv4 } from 'uuid';
import { url_produto } from '../../api.js';

function CarrinhoItem(props) {

    const { deletarItemCarrinho } = useContext(CarrinhoContext);

    return <div className="carrinho-item shadow-sm p-3 bg-body-tertiary rounded">
        <div className="carrinho-item-informacoes d-flex">
            <div className="carrinho-produto-informacoes d-flex flex-column">
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div className="carrinho-produto-titulo">
                        <img className="foto-produto-carrinho" src={url_produto + props.foto} />    
                        <span className='texto-card-carrinho'>{props.quantidade} X {props.nome}</span>
                    </div>
                    
                    <div>
                        <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.valor)}</span>   
                    </div>
                </div>
                <div className="carrinho-produto-opcionais d-flex flex-column">
                    {
                        props.opcionais ?
                            props.opcionais.length > 0 ? props.opcionais.map((o) => {
                                return <div key={uuidv4()} className="carrinho-opcional-nome d-flex justify-content-between carrinho-opcional">
                                    <span>{o.quantidade}x {o.nome}</span>
                                    <span className="text-success">{o.valor > 0 ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(o.valor) : 'GRÁTIS'}</span>
                                </div>
                            })
                                :
                                null
                            :
                            null
                    }
                    {
                        props.obs ?
                            props.obs.length > 0 ? <div className="obs-carrinho">
                                <span>Obs: {props.obs}</span>
                            </div>
                                :
                                null
                            :
                            null
                    }
                </div>
                <div className="borda-carrinho"></div>
                <div className="d-flex justify-content-between">
                    <button className="btn-remover-carrinho"
                        onClick={(e) => { deletarItemCarrinho(props.uuid) }}>Remover</button>
                    <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.total * props.quantidade)}</span>
                </div>
            </div>
        </div>

    </div>
}

export default CarrinhoItem;