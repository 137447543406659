import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { json, Link, useNavigate, useParams } from 'react-router-dom';

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CarrinhoContext } from '../../contexts/carrinho-context';

import { formatar } from "../../react-format";

import './style.css';
import { api } from '../../api';

function RecuperarSenha() {

    const { identificacao } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const Navigate = useNavigate(); 

    const [usuarioLogado, setUsuarioLogado] = useState(""); 
    const [telefone, setTelefone] = useState(""); 
    const [codigoUsuario, setCodigoUsuario] = useState(""); 

    const { corBackground } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);
    
    function handleTelefone(e){
        let obj = formatar(e.target, "cnpj");
        setTelefone(obj.texto);
    }

    function Loggout(){
        localStorage.removeItem(`@${identificacao}-USERDADOS`);
        setTelefone('');
    }

    function EnviarCodigo(){

        let session = localStorage.getItem(`@${identificacao}-SESSION`);

        setIsLoading(true)

        if(telefone === ''){
            alert('Insira o telefone')
            setIsLoading(false);
            return;
        }

        if(telefone.length !== 15){
            alert('Numero de telefone inválido');
            setIsLoading(false);

            return;
        }

        api.post(`usuario/recuperar-senha/enviar-codigo?identificacao=${identificacao}&session=${session}&numero=${telefone}&codigocliente=${codigoUsuario}`)
        .then(response =>{
            Navigate(`/${identificacao}/alterarsenha`);

            toast.success("Código de recuperação enviado com sucesso!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        })
        .catch(err =>{
            if(err.response.status === 405){
                toast.warning(err.response.data, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            if(err.response.status === 500){
                toast.warning('Erro ao enviar o código, tente novamente mais tarde!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        })
    }

    useEffect(() =>{
        let jsonUsuarioLogado = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`));

        let codigo_usuario = '';
        let nome_usuario = '';
        let numero = ''

        if(jsonUsuarioLogado === undefined || jsonUsuarioLogado === '' || jsonUsuarioLogado === null){
            Navigate(`/${identificacao}`);
        }
        else{
            nome_usuario = jsonUsuarioLogado.nome;
            numero = jsonUsuarioLogado.numero;
            codigo_usuario = jsonUsuarioLogado.codigo;
        }

        setUsuarioLogado(nome_usuario);
        setTelefone(numero);
        setCodigoUsuario(codigo_usuario);
    }, [])

    return  <div className="container">                    
                <div className="checkout-login rounded">
                    <div className="login-titulo text-center">
                        {
                            usuarioLogado === undefined || usuarioLogado === "" ?
                            <span className="titulo">Informe o número do seu celular para continuar.</span>
                            :
                            <span className="titulo">Olá <b> {usuarioLogado} </b> informe o número do seu celular para continuar.</span>
                        }
                        <span className="sub">Enviaremos um código via sms para recuperar a senha, este não é o seu numero? {usuarioLogado === undefined || usuarioLogado == "" || usuarioLogado === null ? '' :  <Link onClick={(e) => Loggout()}>clique aqui</Link>}.  </span>
                    </div>

                    <div className="area-login d-flex flex-column gap-4">
                        <div className="d-flex flex-column">
                            <label htmlFor="telefone">Telefone</label>
                            <input className="input-w100" onChange={(e) => handleTelefone(e)} value={telefone} type="text" id="telefone" />
                        </div>
                    </div>

                    <button onClick={(e) => EnviarCodigo()} className="btn-acessar" style={{backgroundColor: corBackground, color: corTexto,}} disabled={isLoading}>
                        {
                        isLoading === true ? 
                                (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                    </div>
                                ) 
                            : 
                                (
                                    <span>Enviar código</span>
                                )
                        }
                    </button>
                </div>
            </div>
}

export default RecuperarSenha;