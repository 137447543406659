import { useEffect } from "react";

function Home(){

    useEffect( () =>{

        document.title = 'Teste';

        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = 'https://cdn.oaistatic.com/_next/static/media/favicon-32x32.630a2b99.png';
        document.getElementsByTagName('head')[0].appendChild(link);

    }, []);

    return <>
        
    <div className="container">
        <span>Página institucional</span>
    </div>

    </>
}

export default Home;