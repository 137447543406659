import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../components/checkout/tiposEntregas/index.jsx";
import TipoPagamento from "../../components/pagamentos/tipopagamento/index.jsx";
import TipoEntrega from "../../components/checkout/tiposEntregas/index.jsx";
import Endereco from "../../components/endereco/endereco/index.jsx";
import ModalNovoEndereco from "../../components/endereco/modalNovoEndereco/index.jsx";
import AreaLogin from "../../components/login/AreaLogin/index.jsx";

import { Helmet } from 'react-helmet';

import { CarrinhoContext } from "../../contexts/carrinho-context.jsx";

import { formatar } from "../../react-format.js";

import { api, url_imagem, url_site  } from "../../api.js";
import "./style.css";

function Checkout() {
    const params = useParams();
    const navigate = useNavigate();

    const [estabelecimentoNome, setEstabelecimentoNome] = useState('');
    const [identificacao] = useState(params.identificacao);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDesconto, setIsLoadingDesconto] = useState(false);

    const [entregas, setEntregas] = useState({});

    const[estabelecimentoLogo, setEstabelecimentoLogo] = useState("");
    const [tipoEntrega, setTipoEntrega] = useState(0);
    const [enderecoEntrega, setEnderecoEntrega] = useState(0);
    const [tipoPagamento, setTipoPagamento] = useState(0);
    const [nomeTipoPagamento, setNomeTipoPagamento] = useState("");

    const [isOpenModalEndereco, setIsOpenModalEndereco] = useState(false);

    const [rua, setRua] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [complemento, setComplemento] = useState("");
    const [cidade, setCidade] = useState("");
    const [uf, setUf] = useState("");

    const [usuarioEnderecos, setUsuarioEnderecos] = useState([])//
    const [estabelecimentoPagamentos, setEstabelecimentoPagamentos] = useState([]);

    const [codigoDesconto, setCodigoDesconto] = useState("");
    const [valorDesconto, setValorDesconto] = useState(0);

    const [troco, setTroco] = useState(0);
    const [trocoTexto, setTrocoTexto] = useState("");
    const [necessitaTroco, setNecessitaTroco] = useState(false);
    const [observacao, setObservacao] = useState("");
    const [isCupomValido, setIsCupomValido] = useState(false);

    const [carrinhoTaxaEntrega, setCarrinhoTaxaEntrega] = useState(0);
    const [carrinhoTotal, setCarrinhoTotal] = useState(0);

    const [isInsertingPedido, setIsInsertingPedido] = useState(false);

    const { totalCarrinho } = useContext(CarrinhoContext);
    const { setTaxaEntrega } = useContext(CarrinhoContext);
    const { taxaEntrega } = useContext(CarrinhoContext);
    const { setCarrinho } = useContext(CarrinhoContext);
    const { isLogged } =  useContext(CarrinhoContext);

    const { setCorBackground, corBackground } = useContext(CarrinhoContext);
    const { setCorTexto, corTexto } = useContext(CarrinhoContext);

    useEffect(() => {
        setCarrinhoTotal(parseFloat(totalCarrinho) + parseFloat(carrinhoTaxaEntrega) - parseFloat(valorDesconto));
    }, [totalCarrinho, carrinhoTaxaEntrega, valorDesconto]);

    const ListarDadosRestaurante = async () => {
        try {
            setIsLoading(true);

            const response = await api.get("estabelecimento/listar", {
                params: {
                    identificacao: identificacao,
                },
            });

            setEstabelecimentoNome(response.data.nome);
            setEstabelecimentoLogo(response.data.logo)
            setRua(response.data.rua);
            setNumero(response.data.numero);
            setBairro(response.data.bairro);
            setComplemento(response.data.complemento);
            setCidade(response.data.cidade);
            setUf(response.data.uf);
            setCorBackground(response.data.corBackground);
            setCorTexto(response.data.corTexto);
            setEstabelecimentoPagamentos(response.data.pagamentos);
            setTaxaEntrega(response.data.taxaEntrega);

            setEntregas(response.data.entregas);

            let jsonEstabelecimento = {
                estabelecimento: params.identificacao,
                rua: response.data.rua,
                numero: response.data.numero,
                bairro: response.data.bairro,
                complemento: response.data.complemento,
                cidade: response.data.cidade,
                uf: response.data.uf,
            };

            localStorage.setItem(`@${identificacao}-DADOS`, JSON.stringify(jsonEstabelecimento));

            setIsLoading(false);
        } catch (error) {
            alert("Erro ao buscar dados " + error);
            //console.log(error);
        }
    };

    function mascaraTroco(e) {
        let obj = formatar(e.target, "MONEY");

        setTroco(obj.value);
        setTrocoTexto(obj.texto);
    }

    const ListarEnderecos = async () => {
        const enderecos_usuario = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
        let session = localStorage.getItem(`@${identificacao}-SESSION`);

        const response = await api.get(
            "usuario/endereco/listar?codigoUsuario=" + enderecos_usuario.codigo + "&identificacao=" + identificacao + "&session=" + session
        );

        if (response?.response?.data) {
            setUsuarioEnderecos([]);
        }

        if (response?.data) {
            enderecos_usuario.enderecos = response.data;
            localStorage.setItem(`@${identificacao}-USERDADOS`, JSON.stringify(enderecos_usuario));
            setUsuarioEnderecos(response.data);
        }
    }

    const ValidarCupom = async () => {
        if (codigoDesconto.length === 0) {
            return;
        }

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
        let session = localStorage.getItem(`@${identificacao}-SESSION`);

        setIsLoadingDesconto(true);

        try {
            const response = await api.get("cupom/validar?codigoCliente=" + jsonUsuario.codigo + "&codigoCupom=" + codigoDesconto + "&valorPedido=" + totalCarrinho + "&identificacao=" + identificacao + "&session=" + session);

            if (response?.response?.data) {

                const msgErro = response.response.data.message;

                toast.error(`${msgErro}`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setIsCupomValido(false);
                setIsLoadingDesconto(false);
            }

            if (response?.data) {
                
                toast.success(`Cupom validado com sucesso.`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setValorDesconto(response.data.valorDesconto);
                setIsCupomValido(true);
                setIsLoadingDesconto(false);
            }

        } catch (error) {
            alert("Erro ao consultar cupom, tente novamente");
            setIsCupomValido(false);
            setIsLoadingDesconto(false);
        }
    };

    const RemoverCupom = () => {
        setIsCupomValido(false);
        setCodigoDesconto("");
    };

    const EnviarPedido = async () => {

        setIsInsertingPedido(true);

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
        const jsonProdutos = JSON.parse(localStorage.getItem(`@${identificacao}-SESSIONCART`));
        let session = localStorage.getItem(`@${identificacao}-SESSION`);

        const jsonPedido = {
            cliente: jsonUsuario.codigo,
            endereco: enderecoEntrega,
            tipoEntrega: tipoEntrega,
            pagamento: tipoPagamento,
            troco: parseFloat(troco),
            precisaTroco: tipoPagamento === 1 ? (necessitaTroco === false ? "sim" : "nao") : "nao",
            codigoCupom: codigoDesconto,
            subtotal: totalCarrinho,

            taxaEntrega: parseFloat(taxaEntrega),
            desconto: parseFloat(valorDesconto),
            total: parseFloat(totalCarrinho) + parseFloat(taxaEntrega) - parseFloat(valorDesconto),
            observacao: observacao,
            restaurante: identificacao,
            itensPedido: jsonProdutos.produtos,
        };

        const response = await api.post(`pedido/inserir?codigoCliente=${jsonUsuario.codigo}&identificacao=${params.identificacao}&session=${session}`, jsonPedido);

        if( response?.response?.data?.message ){

            navigate(`/${identificacao}`);

            const msg = response.response.data.message;
            setIsInsertingPedido(false);

            toast.error(`${msg}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

        if (response?.response?.data.status === "invalido") {
            if (response.response.data.motivo === "Valor minimo não atingido") {
                toast.error("Valor minimo não atingido.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            if (response.response.data.motivo === "Cupom inválido: Quantidade máxima excedido.") {
                toast.error("Quantidade máxima excedida.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            if (response.response.data.motivo === "Cupom vencido") {
                toast.error("Cupom inválido: Cupom vencido.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            if (response.response.data.motivo === "Cupom inválido") {
                toast.error("Cupom inválido: Cupom inválido.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            setIsInsertingPedido(false);
            RemoverCupom();
            return;
        }

        if (response?.status === 200) {
            toast.success("Pedido efetuado com sucesso!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            const codigoPedido = response.data.codigoPedido;
            setIsInsertingPedido(false);
            localStorage.removeItem(`@${identificacao}-SESSIONCART`);
            setCarrinho([]);
            navigate(`/${identificacao}/pedido/${codigoPedido}`);
            setIsInsertingPedido(false)
        }
    };

    const ExecutarAoIniciar = async () => {

        await ListarDadosRestaurante();
        
        const jsonEstabelecimento = JSON.parse(localStorage.getItem(`@${identificacao}-DADOS`));
        const jsonProdutos = JSON.parse(localStorage.getItem(`@${identificacao}-SESSIONCART`));

        const nome_estabelecimento = jsonEstabelecimento.estabelecimento;
        
        if (nome_estabelecimento !== identificacao) {
            navigate("/404");
            return;
        }
        
        if(!jsonProdutos) {
            navigate("/" + identificacao);
            return;
        }
    };

    const BuscaEndereco = () =>{
        let jsonUser = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
        if(jsonUser !== undefined && jsonUser !== null){
            setUsuarioEnderecos(jsonUser.enderecos)
        }
    }

    useEffect(() => {
        ExecutarAoIniciar();
    }, []);

    useEffect(() =>{
        let jsonUser = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));
        if(jsonUser !== undefined && jsonUser !== null){
            setUsuarioEnderecos(jsonUser.enderecos)
        }
    }, [isLoading])

    function alterarTipoEntrega(tipoEntrega) {
        if (tipoEntrega === 1) {
            setCarrinhoTaxaEntrega(taxaEntrega);
        }

        if (tipoEntrega !== 1) {
            setEnderecoEntrega(0);
            setCarrinhoTaxaEntrega(0);
        }

        setTipoEntrega(tipoEntrega);
    }

    function selecionarEndereco(codigoEndereco) {
        setEnderecoEntrega(codigoEndereco);
    }

    function selecionarTipoPagamento(codigoPagamento, nomeTipoPagamento) {

        setTipoPagamento(codigoPagamento);
        setNomeTipoPagamento(nomeTipoPagamento);
    }

    function selecionarNecessitaTroco() {
        setNecessitaTroco(!necessitaTroco);

        if (necessitaTroco == false) {
            setTroco(0);
            setTrocoTexto('');
        }
    }

    function ModalEndereco() {
        setIsOpenModalEndereco(!isOpenModalEndereco);
    }

    return <div className="container">
                <Helmet>
                    <meta charset="UTF-8"></meta>
                    <link rel="icon" type="image/png" href={url_imagem + estabelecimentoLogo} sizes="16x16" />
                    <link rel="icon" type="image/png" href={url_imagem + estabelecimentoLogo} sizes="32x32" />
                    <link rel="icon" type="image/png" href={url_imagem + estabelecimentoLogo} sizes="96x96" />
                    <title>Checkout em: {estabelecimentoNome}</title>
                </Helmet>

                <ModalNovoEndereco isOpen={isOpenModalEndereco}
                                onCloseModal={ModalEndereco}
                                ListarEnderecos={ListarEnderecos}
                                ModalEndereco={ModalEndereco} />

            { 
                isLoading || isLogged ? 
                <div className="checkout">
                    <div className="checkout-titulo">Finalize seu pedido em: {estabelecimentoNome}.</div>

                    <div className="borda"></div>

                    <div className="checkout-tiposentregas">
                        <div className="checkout-tiposentregas-titulo">Escolha a forma de entrega do seu pedido:</div>
                        
                        <div className="row">
                            {
                                entregas.length > 0 ? entregas.map( (entrega) =>{
                                    return (
                                        <TipoEntrega
                                            buscaEndereco={BuscaEndereco}
                                            key={entrega.codigo}
                                            TipoEntrega={entrega.codigo} 
                                            TipoEntregaSelecionado={tipoEntrega} 
                                            Nome={entrega.nome} 
                                            alterarTipoEntrega={alterarTipoEntrega} />
                                    )
                                })  
                                :
                                <span>Nenhum tipo de entrega</span>  
                            }
                            
                        </div>
                    </div>

                    <div className="borda"></div>

                    {tipoEntrega == 1 ? (
                        <div className="checkout-endereco-cliente">
                            <div className="checkout-endereco-cliente-titulo">
                                <span>Selecione o endereço de entrega:</span>
                            </div>
                            <div className="checkout-enderecos-cliente">
                                
                                {usuarioEnderecos.length > 0 ? (
                                    usuarioEnderecos.map(end => {
                                        return <Endereco key={end.codigo}
                                            codigo={end.codigo}
                                            rua={end.rua}
                                            numero={end.numero}
                                            complemento={end.complemento}
                                            bairro={end.bairro}
                                            cidade={end.cidade}
                                            uf={end.uf}
                                            cep={end.cep}
                                            EnderecoSelecionado={enderecoEntrega}
                                            HandleSelecionarEndereco={selecionarEndereco}
                                            ListarEnderecos={ListarEnderecos} />;
                                    })
                                ) : (
                                    <div className="checkout-nenhum-endereco">Nenhum endereco cadastrado.</div>
                                )}
                                <button className="btn-cadastrar-endereco" onClick={ModalEndereco} style={{ backgroundColor: corBackground, color: corTexto }}>
                                    Cadastrar endereço
                                </button>
                            </div>
                        </div>
                    ) : tipoEntrega == 2 ? (
                        <div>
                            <div className="checkout-endereco-cliente-titulo">
                                <span>Endereço para retirar o pedido:</span>
                            </div>

                            <div className="checkout-restaurante-endereco">
                                {rua}, {numero} - {bairro}
                                {complemento.length > 0 ? complemento + " - " : " - "}
                                {cidade} - {uf}
                            </div>
                        </div>
                    ) : null}

                    {tipoEntrega > 0 ? <div className="borda" style={{ marginTop: "30px" }}></div> : null}

                    <div className="row checkout-forma-de-pagamento">
                        <div className="checkout-forma-de-pagamento-titulo">
                            <span>Selecione a forma de pagamento:</span>
                        </div>

                        {estabelecimentoPagamentos.map(pag => {
                            return <TipoPagamento key={pag.codigo}
                                codigo={pag.codigo}
                                nome={pag.nome}
                                imagem={pag.imagem}
                                PagamentoSelecionado={tipoPagamento}
                                SelecionarTipoPagamento={selecionarTipoPagamento} />;
                        })}

                        {nomeTipoPagamento == 'Dinheiro' ? (
                            <div className="checkout-troco">
                                <span className="troco-titulo">Troco para?</span>
                                <input className="input-text" type="text" onChange={mascaraTroco} value={trocoTexto} disabled={necessitaTroco === false ? false : true} />

                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={selecionarNecessitaTroco} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Não precisa de troco {necessitaTroco}
                                    </label>
                                </div>

                                {necessitaTroco === false ? (
                                    troco < totalCarrinho ? (
                                        <span className="checkout-troco-mensagem">
                                            Valor do troco menor que o valor do pedido de {new Intl.NumberFormat("pt-BR", {style: "currency", currency:"BRL",}).format(carrinhoTotal)}
                                        </span>
                                    ) : null
                                ) : null}
                            </div>
                        ) : null}
                    </div>

                    <div className="borda"></div>

                    <div className="checkout-desconto">
                        <span className="checkout-desconto-titulo">Possuí algum cupom de desconto?</span>

                        <div className="d-flex justify-content-between align-items-center mt-2 gap-2">
                            <input
                                className="input-text"
                                type="text"
                                onChange={e => {
                                    setCodigoDesconto(e.target.value);
                                }}
                                value={codigoDesconto}
                            />

                            <button
                                className="btn-cupom"
                                style={{
                                    backgroundColor: isCupomValido === true ? "red" : corBackground,
                                    color: corTexto,
                                }}
                                onClick={e => {
                                    {
                                        isCupomValido === true ? RemoverCupom() : ValidarCupom();
                                    }
                                }}
                            >
                                {isLoadingDesconto === true ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                    </div>
                                ) : isCupomValido === true ? (
                                    "Remover"
                                ) : (
                                    "Validar"
                                )}
                            </button>
                        </div>
                    </div>

                    <div className="borda"></div>

                    <div className="checkout-observacao">
                        <div className="">
                            <span>Alguma observação?</span>
                        </div>
                        <div className="form-floating">
                            <textarea
                                className="checkout-observacao-mensagem"
                                placeholder="Ex: Entregar na casa dos fundos"
                                style={{ height: "100px" }}
                                onChange={e => {
                                    setObservacao(e.target.value);
                                }}
                                maxLength={100}
                                value={observacao}
                            ></textarea>
                            <div className="observacao-caracters d-flex justify-content-end">
                                <span>{observacao.length} / 100</span>
                            </div>
                        </div>
                    </div>

                    <div className="borda"></div>

                    <div className="checkout-valores d-flex flex-column gap-1">
                        <div className="checkout-valores-subtotal d-flex justify-content-between">
                            <span>Subtotal:</span>
                            <span>
                                {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(totalCarrinho)}
                            </span>
                        </div>

                        <div className="checkout-valores-taxaentrega d-flex justify-content-between">
                            <span>Taxa de Entrega:</span>
                            <span>
                                {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(carrinhoTaxaEntrega)}
                            </span>
                        </div>

                        <div className="checkout-valores-desconto d-flex justify-content-between">
                            <span>Desconto:</span>
                            <span>
                                {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(valorDesconto)}
                            </span>
                        </div>

                        <div className="checkout-valores-total d-flex justify-content-between">
                            <span>Total:</span>
                            <span>
                                {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(carrinhoTotal)}
                            </span>
                        </div>
                    </div>

                    <div className="checkout-finalizar-pedido">
                        <button
                            className="btn-finalizar-pedido"
                            style={{
                                backgroundColor: corBackground,
                                color: corTexto,
                            }}
                            onClick={e => {
                                EnviarPedido();
                            }}
                            disabled={
                                tipoEntrega === 0 ? true
                                    :
                                    tipoEntrega === 1 && enderecoEntrega === 0 ? true
                                        :
                                        tipoPagamento === 0 ? true
                                            :
                                            nomeTipoPagamento === 'Dinheiro' && necessitaTroco === false && troco < totalCarrinho ? true
                                                :
                                                false
                            }
                        >
                            {
                                isInsertingPedido == false ? tipoEntrega === 0 ? "Selecione o tipo de entrega"
                                    :
                                    tipoEntrega === 1 && enderecoEntrega === 0 ? "Selecione o endereço"
                                        :
                                        tipoPagamento === 0 ? "Selecione a forma de pagamento"
                                            :
                                            nomeTipoPagamento === 'Dinheiro' && necessitaTroco === false && troco < totalCarrinho ? "Verifique o troco"
                                                :
                                                "Finalizar pedido"
                                    :
                                    null
                            }
                            {
                                isInsertingPedido == true ? <div className="d-flex justify-content-center align-items-center gap-2">
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                    <span>Finalizando pedido...</span>
                                </div>
                                    :
                                    null
                            }
                        </button>
                    </div>
                </div>
                :
                <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <AreaLogin area='checkout' />
                </div>
            }

            </div>
}

export default Checkout;
