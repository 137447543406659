import Modal from 'react-modal/lib/components/Modal';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import apicep from '../../../api-cep.js';
import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../../../api";
import { formatar } from '../../../react-format.js';
import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';

import './style.css';

import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdSearch } from "react-icons/io";
import { LiaStar } from 'react-icons/lia';

function ModalNovoEndereco(props) {

    const params = useParams();

    const [identificacao, setIdentificacao] = useState(params.identificacao);

    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');
    const [cep, setCep] = useState('');

    const [isCepLoading, setIsCepLoading] = useState(false);
    const [mensagemViaCep, setMsgViaCep] = useState('');

    const [showEndereco, setShowEndereco] = useState(false);

    const [isInsertingEndereco, setIsInsertingEndereco] = useState(false);

    const { corBackground } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);

    useEffect(() => {
        if (props.isOpen === false) {

            setRua('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setUf('');
            setCep('');

            setMsgViaCep('');
            setShowEndereco(false);
            setIsCepLoading(false);

        }

    }, [props.isOpen]);

    const buscarCep = async () => {
        setIsCepLoading(true);
        setMsgViaCep('');

        apicep.get(`${cep}/json`)
            .then(response => {

                if (response?.data || response?.status === 200) {


                    if (response?.data?.erro === true) {
                        setMsgViaCep('Cep inválido... Tente outro');
                        setIsCepLoading(false);
                        return;
                    }

                    setCep(response.data.cep);
                    setRua(response.data.logradouro);
                    setComplemento(response.data.complemento);
                    setBairro(response.data.bairro);
                    setCidade(response.data.localidade);
                    setUf(response.data.uf);

                    setShowEndereco(true);

                    setIsCepLoading(false);
                }

            })
            .catch(err => {
                alert('Ocorreu um erro.. tente novamente');
                return;
            });
    }

    const SalvarEndereco = async () => {
        setIsInsertingEndereco(true);

        const jsonUsuario = JSON.parse(localStorage.getItem(`@${identificacao}-USERDADOS`));

        if (!(jsonUsuario === null)) {
            const jsonEndereco = {
                rua,
                numero,
                complemento,
                bairro,
                cidade,
                uf,
                cep,
                padrao: 'N'
            }

            let session = localStorage.getItem(`@${identificacao}-SESSION`);

            const response = await api.post(
                `usuario/endereco/salvar?codigoCliente=${jsonUsuario.codigo}&identificacao=${identificacao}&session=${session}`,
                jsonEndereco
            );

            if (response?.response?.status) {

            }

            if (response?.status === 200) {

                await props.ListarEnderecos(jsonUsuario.codigo);

                toast.success("Endereço cadastrado com sucesso", {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setIsInsertingEndereco(false);
                props.ModalEndereco();
            }

        }

    }

    function handleCEP(e) {
        let obj = formatar(e.target, "CEP");
        setCep(obj.texto);
    }

    return <Modal isOpen={props.isOpen}
        onRequestClose={props.onCloseModal}
        overlayClassName="modal-overlay"
        className="modal-background" >

        <div className="d-flex flex-column">

            <div className="area-fechar-modal d-flex justify-content-end fixed">
                <button onClick={props.onCloseModal} type="button" className="react-modal-close">
                    <AiFillCloseCircle size={32} color='#555' />
                </button>
            </div>

            <div className="container pb-4">
                {
                    showEndereco === false ? <div>
                        <div className="d-flex flex-column gap-2">

                            <span>Digite o cep</span>
                            <div className="novo-endereco-cep d-flex flex-row align-align-items-center gap-2">

                                <input className="input-w100" onChange={handleCEP} />

                                <button className="btn-buscar-cep" style={{ backgroundColor: corBackground, color: corTexto }} onClick={buscarCep}>
                                    {
                                        isCepLoading === true ? <div className="spinner-border spinner-border-sm" role="status"></div>
                                            :
                                            <IoMdSearch />
                                    }
                                </button>

                            </div>
                            {
                                mensagemViaCep.length > 0 ? <span>
                                    {mensagemViaCep}
                                </span>
                                    :
                                    null
                            }
                        </div>
                    </div>
                        :
                        <div>
                            <div className="row">
                                <div className="col-md-9 col-sm-12 d-flex flex-column mb-3">
                                    <label>Rua</label>
                                    <input className="input-w100" value={rua} type="text" onChange={(e) => setRua(e.target.value)} />
                                </div>
                                <div className="col-md-3 col-sm-12 d-flex flex-column mb-3">
                                    <label>Número</label>
                                    <input className="input-w100" value={numero} type="text" onChange={(e) => setNumero(e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 d-flex flex-column mb-3">
                                    <label>Complemento</label>
                                    <input className="input-w100" type="text" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                                </div>
                                <div className="col-md-6 col-sm-12 d-flex flex-column mb-3">
                                    <label>Bairro</label>
                                    <input className="input-w100" type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 d-flex flex-column mb-3">
                                    <label>Cidade</label>
                                    <input className="input-w100" disabled={true} value={cidade} type="text" onChange={(e) => setCidade(e.target.value)} />
                                </div>
                                <div className="col-md-2 col-sm-12 d-flex flex-column mb-3">
                                    <label>Uf</label>
                                    <input className="input-w100" disabled={true} value={uf} type="text" maxLength={2} onChange={(e) => setUf(e.target.value)} />
                                </div>
                                <div className="col-md-4 col-sm-12 d-flex flex-column mb-3">
                                    <label>Cep</label>
                                    <input className="input-w100" disabled={true} value={cep} type="text" maxLength={9} onChange={(e) => setCep(e.target.value)} />
                                </div>
                            </div>

                            <button className="btn-inserir-endereco"
                                style={{ backgroundColor: corBackground, color: corTexto }}
                                onClick={SalvarEndereco}
                                disabled={
                                    rua === '' || numero === '' || bairro === '' || cidade === '' ||
                                        uf === '' || cep === '' ? true : false}>
                                {
                                    isInsertingEndereco === true ? <div className="d-flex justify-content-center align-items-center gap-2">
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                        <span>Salvando...</span>
                                    </div>
                                        :
                                        rua === '' || numero === '' || bairro === '' || cidade === '' ||
                                            uf === '' || cep === '' ? 'Preencha os dados' : 'Salvar'
                                }
                            </button>
                        </div>
                }
            </div>

        </div>

    </Modal>
}

export default ModalNovoEndereco;