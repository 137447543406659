

function eNumeroValido(valor){
    return !isNaN(valor) && isFinite(valor);        
} 

function eNumeroFloatValido(valor){
    return !isNaN(parseFloat(valor)) && isFinite(valor);        
}

function validaData(dataRecebida){
    var dataAux = dataRecebida.split("/");
    var ano = dataAux[2];
    var mes = dataAux[1];
    var dia = dataAux[0];

    if ( (ano === null) || (mes === null) || (dia === null) )
        return false;

    if ( eNumeroValido(ano) && eNumeroValido(mes) && eNumeroValido(dia) ){

        if (ano > 0){
            if ( (mes<=0) || (mes>12) )
                return false;

            if ( (dia>31) || (dia <=0) )
                return false;
        
            if ( (dia>=30) && (mes===2) )
                return false;

            if ( (dia===29) && (mes===2) && ( (ano % 4) != 0 ) )
                return false;

            if ( (dia===31) && ( (mes===2) || (mes===4) || (mes===6) || (mes===9) || (mes===11)  ) )                    
                return false;

            if ( (1<=mes<=12) && (1<=dia<=31) )
                return true;
        }            
    }
        
    return false;

}

function formataIE(uf){
    var mascara= "";
    
    switch (uf){            
        case 'AC':  mascara = '##.###.###/###-##'; break;
        case 'AL':  mascara = '#########'; break;
        case 'AP':  mascara = '#########'; break;
        case 'AM':  mascara = '##.###.###-#'; break;
        case 'BA':  mascara = '######-##'; break;
        case 'CE':  mascara = '########-#'; break;
        case 'DF':  mascara = '###########-##'; break;
        case 'ES':  mascara = '#########'; break;
        case 'GO':  mascara = '##.###.###-#'; break;
        case 'MA':  mascara = '#########'; break;
        case 'MT':  mascara = '##########-#'; break;
        case 'MS':  mascara = '#########'; break;
        case 'MG':  mascara = '###.###.###/####'; break;
        case 'PA':  mascara = '##-######-#'; break;
        case 'PB':  mascara = '########-#'; break;
        case 'PR':  mascara = '########-##'; break;
        case 'PE':  mascara = '##.#.###.#######-#'; break;
        case 'PI':  mascara = '#########'; break;
        case 'RJ':  mascara = '##.###.##-#'; break;
        case 'RN':  mascara = '##.###.###-#'; break;
        case 'RS':  mascara = '###/#######'; break;
        case 'RO':  mascara = '###.#####-#'; break;
        case 'RR':  mascara = '########-#'; break;
        case 'SC':  mascara = '###.###.###'; break;
        case 'SP':  mascara = '###.###.###.###'; break;
        case 'SE':  mascara = '#########-#'; break;
        case 'TO':  mascara = '###########'; break;
        default: mascara = '###########';
    }

    return mascara;
}

function mask(mascara, str){
    var x = 0
    var p = 0;
    var res = "";            
    
    if (str.length === 0)
            return "";

    for (var x = 0; x <= mascara.length - 1; x++){                
        

        if (mascara.charAt(x) === '#') {                    
            res = res + str.charAt(p);
            p++;
        } else 
            res = res + mascara.charAt(x);                                    
        
        if (p === str.length)
            break;
    }

    return res;
}

function formatar(target, formato, extra){
    
    var texto = target.value.replace(/\D/g, "");
    var obj = {};
    
    if (formato.toLowerCase() === "telefonefixo") 
        texto = mask("(##) ####-####", texto);
    else  if (formato.toLowerCase() === "celular") 
        texto = mask("(##) #####-####", texto);
    else  if (formato.toLowerCase() === "cnpj") 
        texto = mask("##.###.###/####-##", texto);
    else  if (formato.toLowerCase() === "cpf") 
        texto = mask("###.###.###-##", texto);
    else  if (formato.toLowerCase() === "ie") 
        texto = mask(formataIE(extra), texto);            
    else  if (formato.toLowerCase() === "cnpjorcpf") {
        if (texto.length > 11)
            texto = mask("##.###.###/####-##", texto)
        else
            texto = mask("###.###.###-##", texto)
    }
    else  if (formato.toLowerCase() === "personalizado") 
        texto = mask(extra, texto);
    else  if (formato.toLowerCase() === "valor") {
        if (texto.length > 0)
        {
            obj.value = parseFloat(texto)/100;
            texto = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2}).format(parseFloat(texto)/100);
        }
    }
    else  if (formato.toLowerCase() === "money") {
        if (texto.length > 0)                
        {
            obj.value = parseFloat(texto)/100;
            texto = new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(parseFloat(texto)/100);
        }
    }
    else  if (formato.toLowerCase() === "cep") 
        texto = mask("#####-###", texto);
    else  if (formato.toLowerCase() === "data") {
        texto = texto.substring(0, 8);
        texto = mask("##/##/####", texto);

        if (texto.length === 10 && !validaData(texto))
            texto = "";
    }
    else  if (formato.toLowerCase() === "peso") {
        if (texto.length > 0){
            obj.value = parseFloat(texto)/1000;
            texto = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 3}).format(parseFloat(texto)/1000);                
        }
    }                

    obj.texto = texto;

    if (!obj.value)
        obj.value = texto;
    
    target.value = texto;
    return obj;
}

export {formatar};