import React, { useContext } from "react";

import { ReactComponent as Desconto } from "../../../assets/images/desconto2.svg";
import { CarrinhoContext } from "../../../contexts/carrinho-context";

import { FaCrown } from "react-icons/fa";
import { LuVegan } from "react-icons/lu";
import { TbPlant } from "react-icons/tb";
import { TbCandyOff } from "react-icons/tb";
import { LuMilkOff } from "react-icons/lu";
import { GiFruitTree } from "react-icons/gi";
import { PiThermometerCold } from "react-icons/pi";

import {api, url_banner, url_imagem, url_produto, URL_SERVER} from "../../../api.js";

import "./style.css";


function ProdutoCardapio(props) {

    const { corTexto } = useContext(CarrinhoContext);
    const { corBackground, corBackgroundHex } = useContext(CarrinhoContext);

    return (
    <div className={props.pagina === 'pesquisa-produto' ? "col-12" : "col-lg-6 col-md-6 col-sm-12"} onClick={ props.controlaEstoque === 'S' && props.estoque === 0 ? null : (e) =>{props.abrirModal(props.produtoClicado)} }>
        
        <div className="card-produto">
            {
                props.novidade ? (
                    <div>
                        <div className="card-produto-novidade">
                            <div className="card-produto-novidade-faixa" style={{backgroundColor: corBackground}}>
                                <span className="card-produto-promocao-texto"
                                style={{color: corTexto}}>Novidade</span>
                            </div>
                        </div>
                    </div>
                )
                :
                null
            }
            {
                props.valor_vendapromo > 0 ? (
                    <div className="card-produto-promocao">
                        <div className="card-produto-promocao-desconto" style={{backgroundColor: corBackground}}>
                            <span className="card-produto-promocao-texto"
                            style={{color: corTexto}}>Promoção</span>
                        </div>
                    </div>
                )
                :
                null
            }

            {
                props.controlaEstoque === 'S' && props.estoque === 0 ? (
                    <div>
                        <div className="card-produto-indisponivel-background"></div>
                        <div className="card-produto-indisponivel">
                            <div className="card-produto-indisponivel-faixa" style={{backgroundColor: corBackground}}>
                                <span className="card-produto-promocao-texto"
                                style={{color: corTexto}}>Indisponível</span>
                            </div>
                        </div>
                    </div>
                )
                :
                null
            }
        
            <div style={{padding: '0.5rem'}}>
                <div className="d-flex gap-2">
                    <div className="card-produto-imagem d-flex align-items-center">
                        <img src={url_produto + props.imagem} alt="Imagem produto" />
                    </div>
                    <div className="card-produto-informacoes d-flex flex-column">
                        <div className="card-produto-nome">
                            <span >
                                {props.nome} 
                            </span>
                        </div>
                        <div className="card-produto-descricao">
                            <span>
                                {props.descricao}
                            </span>
                        </div>
                        
                    </div>
                </div>
            
                <div className="mt-2 card-produto-preco d-flex justify-content-start justify-content-between">
                    <div>
                        {
                            props.vegetariano === 'S' ?
                            <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)`, color: corBackground }} title="Produto vegetariano">
                                <LuVegan size={14} color={`rgb(${corBackgroundHex})`} />
                            </span>
                            :
                            ''
                        }

                        {
                            props.vegano === 'S' ?
                            <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                <TbPlant size={14} color={`rgb(${corBackgroundHex})`} />
                            </span>
                            :
                            ''
                        }

                        {
                            props.acucar === 'S' ?
                            <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                <TbCandyOff size={14} color={`rgb(${corBackgroundHex})`} />
                            </span>
                            :
                            ''
                        }

                        {
                            props.lactose === 'S' ?
                            <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                <LuMilkOff size={14} color={`rgb(${corBackgroundHex})`} />
                            </span>
                            :
                            ''
                        }

                        {
                            props.organico === 'S' ?
                            <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                <GiFruitTree size={14} color={`rgb(${corBackgroundHex})`} />
                            </span>
                            :
                            ''
                        }

                        {
                            props.gelado === 'S' ?
                            <span className="bdg-outline" style={{border: `1px solid rgb(${corBackgroundHex}, 0.4)`, backgroundColor: `rgb(${corBackgroundHex} , 0.1)` }} title="Produto vegetariano">
                                <PiThermometerCold size={14} color={`rgb(${corBackgroundHex})`} />
                            </span>
                            :
                            ''
                        }
                    </div>

                    <div className="d-flex">
                        <div className={props.valor_vendapromo > 0 ? 'card-produto-venda valor-antigo' : 'valor-venda'}>
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.valor_venda)}
                        </div>

                        {
                            props.valor_vendapromo > 0 ? <div className={props.valor_vendapromo > 0 ? 'ms-2 card-produto-venda-promocional valor-venda' : 'card-produto-venda-promocional'}>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.valor_vendapromo)}
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

}

export default ProdutoCardapio;