import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';

import { api } from "../../../api.js";
import { CarrinhoContext } from '../../../contexts/carrinho-context.jsx';

import { toast } from "react-toastify";

import './style.css';

function AreaLoginVerify(props) {

    const params = useParams();

    const [isLoadVerify, setIsLoadVerify] = useState(true);
    const {isLogged, setIsLogged} = useContext(CarrinhoContext);
    const {isAutenticaded, setIsAutenticaded} = useContext(CarrinhoContext);

    const [usuarioLogado, setUsuarioLogado] = useState(""); 
    const [identificacao, setIdentificacao] = useState(params.identificacao);
    const [isLoading, setIsLoading] = useState(false);

    const [senha, setSenha] = useState("");
    const [senha2, setSenha2] = useState("");

    const [isVerifyed, setIsVerifyed] = useState(false);

    const { corBackground } = useContext(CarrinhoContext);
    const { corTexto } = useContext(CarrinhoContext);

    const VerificaUsuario = async () =>{
        try{
            let jsonUsuario = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`) );
            let id_usuario = jsonUsuario.codigo;

            api.get(`cliente/verifica_usuario?identificacao=${identificacao}&codigo=${id_usuario}`)
            .then(response =>{
                setIsVerifyed(response.data === true ? true : false);
                setIsLoadVerify(false);
            })
            .catch(err =>{
                console.log(err);
            })
        }
        catch(e){
            alert('Erro' + e);
            setIsLoadVerify(false);
            return;
        }
    }

    useEffect(() =>{
        let jsonUsuarioLogado = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`));
        let nome_usuario = jsonUsuarioLogado.nome;

        setUsuarioLogado(nome_usuario || "");
    }, [])
    
    useEffect(() =>{
        if(isLogged){
            VerificaUsuario();
        }
    }, [])

    const verificaConta = async (e) =>{
        e.preventDefault();

        setIsLoading(true);
        
        if(senha === "" || senha2 === ""){
            setIsLoading(false);
            return;
        }

        if(senha !== senha2){
            setIsLoading(false);
            return;
        }

        try{

            let jsonUsuario = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`) );
            let id_usuario = jsonUsuario.codigo;

            const response = await api.post("cliente/verificar?identificacao=" + identificacao + "&senha="+ senha + "&codigo=" + id_usuario);
            if(response?.data){
                setIsVerifyed(true);
                setIsAutenticaded(true);

                jsonUsuario.sessionToken = response.data
                localStorage.setItem(`@${identificacao}-USERDADOS`, JSON.stringify(jsonUsuario));

                setSenha('');
                setSenha2('');

                if(props.area === "perfil"){
                    props.listarDadosPerfil();
                }

                if(props.area === "meuspedidos"){
                    props.listarPedidos();
                }

                setIsLoadVerify(false);
                setIsLoading(false);
            }
        }
        catch(e){
            setIsLogged(false);
            alert('Erro' + e);
            return;
        }
    }

    const verificaSenha = async (e) =>{
        e.preventDefault();

        setIsLoading(true);
        
        if(senha === ""){
            setIsLoading(false);
            return;
        }

        try{
            let jsonUsuario = JSON.parse( localStorage.getItem(`@${identificacao}-USERDADOS`) );
            let id_usuario = jsonUsuario.codigo;

            const response = await api.post("cliente/verificarsenha?identificacao=" + identificacao + "&senha="+ senha + "&codigo=" + id_usuario);
            
            if(response.status === 200){
                if(response?.data){
                    
                    jsonUsuario.sessionToken = response.data
                    localStorage.setItem(`@${identificacao}-USERDADOS`, JSON.stringify(jsonUsuario));
                    
                    if(props.area === "perfil"){
                        props.listarDadosPerfil();
                    }
                    
                    if(props.area === "meuspedidos"){
                        props.listarPedidos();
                    }
                    
                    setIsAutenticaded(true);

                    setSenha('');

                    setIsLoadVerify(false)
                    setIsLoading(false);
                }
            }
            else{
                if(response.response.status === 405){
                    setIsLoading(false);
                    setSenha('');
                    toast.warning(response.response.data, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        }
        catch(e){
            setIsLoading(false);
            alert('Erro' + e);
            return;
        }
    }

    return  <div className="container">
                {
                    isLoadVerify ?
                    <div> </div>
                    :
                    <div> 
                        {
                        isVerifyed === true ?
                        <div className="checkout-login rounded">
                            <div className="login-titulo text-center">
                                <span className="titulo">Olá <b> {usuarioLogado} </b></span>
                                <span className="sub">Informe a sua senha para continuar</span>
                            </div>

                            <div className="area-login d-flex flex-column gap-4">
                                <div className="d-flex flex-column">
                                    <label htmlFor="senha">Senha</label>
                                    <input className="input-w100" onChange={(e) => setSenha(e.target.value)} value={senha} type="password" id="senha" />
                                </div>
                            </div>
                            <div>
                                <Link to={`/${identificacao}/recuperarsenha`}>Recuperar senha</Link>
                            </div>

                            <button onClick={(e) => verificaSenha(e)} className="btn-acessar" style={{backgroundColor: corBackground, color: corTexto,}} disabled={isLoading}>
                                {
                                    isLoading === true ? 
                                        (
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border spinner-border-sm" role="status"></div>
                                            </div>
                                        ) 
                                    : 
                                        (
                                            <span>Entrar</span>
                                        )
                                }
                            </button>
                        </div>
                        :
                        <div className="checkout-login rounded">
                            <div className="login-titulo text-center">
                                <span className="titulo"><b>Falta informações</b></span>
                                <span className="sub">Preencha senha para garantir que só você tenha acesso as suas informações.</span>
                            </div>

                            <div className="area-login d-flex flex-column gap-4">
                                <div className="d-flex flex-column">
                                    <label htmlFor="senha">Senha</label>
                                    <input className="input-w100" onChange={(e) => setSenha(e.target.value)} value={senha} type="password" id="senha" />
                                </div>
                            </div>

                            
                            <div className="area-login d-flex flex-column gap-4">
                                <div className="d-flex flex-column">
                                    <label htmlFor="confirmacao-senha">Confirme a senha</label>
                                    <input className="input-w100" onChange={(e) => setSenha2(e.target.value)} value={senha2} type="password" id="confirmacao-senha" />
                                </div>
                            </div>

                            <button onClick={(e) => verificaConta(e) } className="btn-acessar" style={{backgroundColor: corBackground, color: corTexto,}} disabled={isLoading}>
                                {isLoading === true ? (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                    </div>
                                ) : (
                                    <span>Entrar</span>
                                )}
                            </button>
                        </div>
                        }
                    </div>
                }
            </div>
}

export default AreaLoginVerify;